import jan_27_21 from "../images/assets/news/ReadyfortheNewNormal.jpg";
import jan_14_21 from "../images/assets/news/makar-sankranti.png";
import dec_06_20 from "../images/assets/news/geetachanting.jpg";
import nov_29_20 from "../images/assets/news/seniorfelicitation.png";
import nov_29_20_2 from "../images/assets/news/primaryfelicitation.png";
import oct_02_20 from "../images/assets/news/hindidiwas.jpg";
import sept_14_20 from "../images/assets/news/hindidiwas2.jpg";
import aug_15_20 from "../images/assets/news/independenceday2020.jpg";
import aug_15_20_2 from "../images/assets/news/independenceday2020_2.jpg";
import aug_03_20 from "../images/assets/news/devotionfaith.jpg";
import july_05_20 from "../images/assets/news/gurupurnima.jpg";
import may_08_20 from "../images/assets/news/foundersday.jpg";
// const july_08_21 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews4.jpeg";
import geetajayanti1 from "../images/assets/news/geetajayanti/geetajayanti1.jpg";
import geetajayanti2 from "../images/assets/news/geetajayanti/geetajayanti2.jpg";
import geetajayanti3 from "../images/assets/news/geetajayanti/geetajayanti3.jpg";
import geetajayanti4 from "../images/assets/news/geetajayanti/geetajayanti4.jpg";
import geetajayanti5 from "../images/assets/news/geetajayanti/geetajayanti5.jpg";

import hindiDiwasPdf from "../assets/pdfs/hindidiwas.pdf";

import yoga1 from "../images/assets/news/yoga/yoga-1.png";
import yoga2 from "../images/assets/news/yoga/yoga-2.png";
import yoga3 from "../images/assets/news/yoga/yoga-3.png";
import yoga4 from "../images/assets/news/yoga/yoga-4.png";
import yoga5 from "../images/assets/news/yoga/yoga-5.png";
import yoga6 from "../images/assets/news/yoga/yoga-6.png";
import yoga7 from "../images/assets/news/yoga/yoga-7.png";
import yoga8 from "../images/assets/news/yoga/yoga-8.png";
import yoga9 from "../images/assets/news/yoga/yoga-9.png";
import yoga10 from "../images/assets/news/yoga/yoga-10.png";
import yoga11 from "../images/assets/news/yoga/yoga-11.png";
import yoga12 from "../images/assets/news/yoga/yoga-12.png";
import yoga13 from "../images/assets/news/yoga/yoga-13.png";

import jan_12_21_1 from "../images/assets/news/12jan/1.jpeg";
import jan_12_21_2 from "../images/assets/news/12jan/2.jpeg";
import jan_12_21_3 from "../images/assets/news/12jan/3.jpeg";
import jan_12_21_4 from "../images/assets/news/12jan/4.jpeg";

import jan_13 from "../images/assets/news/13_jan.png";

import jan_30_21_1 from "../images/assets/news/30jan/1.jpeg";
import jan_30_21_2 from "../images/assets/news/30jan/2.jpeg";
import jan_30_21_3 from "../images/assets/news/30jan/3.jpeg";

import feb_08_21_1 from "../images/assets/news/08feb/1.jpeg";
import feb_08_21_2 from "../images/assets/news/08feb/2.jpeg";
import feb_08_21_3 from "../images/assets/news/08feb/3.jpeg";

import feb_18_21_1 from "../images/assets/news/18feb/1.jpeg";
import feb_18_21_2 from "../images/assets/news/18feb/2.jpeg";
import feb_18_21_3 from "../images/assets/news/18feb/3.jpeg";
import feb_18_21_4 from "../images/assets/news/18feb/4.jpeg";

import feb_25_21_1 from "../images/assets/news/25feb/1.jpeg";
import feb_25_21_2 from "../images/assets/news/25feb/2.jpeg";
import feb_25_21_3 from "../images/assets/news/25feb/3.jpeg";
import feb_25_21_4 from "../images/assets/news/25feb/4.jpeg";
import feb_25_21_5 from "../images/assets/news/25feb/5.jpeg";

import mar_06_21 from "../images/assets/news/06_march_2021.png";

import mar_08_21_1 from "../images/assets/news/08march/1.jpeg";
import mar_08_21_2 from "../images/assets/news/08march/2.jpeg";
import mar_08_21_3 from "../images/assets/news/08march/3.jpeg";

import mar_08_21_2_1 from "../images/assets/news/08march2/1.jpeg";
import mar_08_21_2_2 from "../images/assets/news/08march2/2.jpeg";
import mar_08_21_2_3 from "../images/assets/news/08march2/3.jpeg";
import mar_08_21_2_4 from "../images/assets/news/08march2/4.jpeg";

import mar_16_21_1 from "../images/assets/news/16march/1.jpeg";
import mar_16_21_2 from "../images/assets/news/16march/2.jpeg";
import mar_16_21_3 from "../images/assets/news/16march/3.jpeg";
import mar_16_21_4 from "../images/assets/news/16march/4.jpeg";
import mar_16_21_5 from "../images/assets/news/16march/5.jpeg";

import april_08_21_1 from "../images/assets/news/08april/1.jpeg";
import april_08_21_2 from "../images/assets/news/08april/2.jpeg";
import april_08_21_3 from "../images/assets/news/08april/3.jpeg";
import april_08_21_4 from "../images/assets/news/08april/4.jpeg";

import april_13_21_1 from "../images/assets/news/13april/1.jpeg";
import april_13_21_2 from "../images/assets/news/13april/2.jpeg";
import april_13_21_3 from "../images/assets/news/13april/3.jpeg";
import april_13_21_4 from "../images/assets/news/13april/4.jpeg";

import april_17_21_1 from "../images/assets/news/17april/1.jpeg";
import april_17_21_2 from "../images/assets/news/17april/2.jpeg";
import april_17_21_3 from "../images/assets/news/17april/3.jpeg";

import april_21_21_1 from "../images/assets/news/21april/1.jpeg";
import april_21_21_2 from "../images/assets/news/21april/2.jpeg";
import april_21_21_3 from "../images/assets/news/21april/3.jpeg";
import april_21_21_4 from "../images/assets/news/21april/4.jpeg";

import april_22_21 from "../images/assets/news/22_april_2021.png";

import april_22_21_1 from "../images/assets/news/22april/1.jpeg";
import april_22_21_2 from "../images/assets/news/22april/2.jpeg";
import april_22_21_3 from "../images/assets/news/22april/3.jpeg";
import april_22_21_4 from "../images/assets/news/22april/4.jpeg";
import april_22_21_5 from "../images/assets/news/22april/5.jpeg";

import april_27_21_1 from "../images/assets/news/27april/1.jpeg";
import april_27_21_2 from "../images/assets/news/27april/2.jpeg";
import april_27_21_3 from "../images/assets/news/27april/3.jpeg";
import april_27_21_4 from "../images/assets/news/27april/4.jpeg";

import may_08_21_1 from "../images/assets/news/08may/1.jpeg";
import may_08_21_2 from "../images/assets/news/08may/2.jpeg";
import may_08_21_3 from "../images/assets/news/08may/3.jpeg";
import may_08_21_4 from "../images/assets/news/08may/4.jpeg";

import june_05_21_1 from "../images/assets/news/05june/1.jpeg";
import june_05_21_2 from "../images/assets/news/05june/2.jpeg";
import june_05_21_3 from "../images/assets/news/05june/3.jpeg";
import june_05_21_4 from "../images/assets/news/05june/4.jpeg";

import june_05_21 from "../images/assets/news/05june.png";

import june_10_21_1 from "../images/assets/news/10june/1.jpeg";
import june_10_21_2 from "../images/assets/news/10june/2.jpeg";
import june_10_21_3 from "../images/assets/news/10june/3.jpeg";
import june_10_21_4 from "../images/assets/news/10june/4.jpeg";

import june_10_21_2_1 from "../images/assets/news/10june2/1.jpeg";
import june_10_21_2_2 from "../images/assets/news/10june2/2.jpeg";
import june_10_21_2_3 from "../images/assets/news/10june2/3.jpeg";
import june_10_21_2_4 from "../images/assets/news/10june2/4.jpeg";

import june_10_21_3_1 from "../images/assets/news/10june3/1.jpeg";
import june_10_21_3_2 from "../images/assets/news/10june3/2.jpeg";
import june_10_21_3_3 from "../images/assets/news/10june3/3.jpeg";
import june_10_21_3_4 from "../images/assets/news/10june3/4.jpeg";

import june_16_21_1 from "../images/assets/news/16june/1.jpeg";
import june_16_21_2 from "../images/assets/news/16june/2.jpeg";
import june_16_21_3 from "../images/assets/news/16june/3.jpeg";
import june_16_21_4 from "../images/assets/news/16june/4.jpeg";
import june_16_21_5 from "../images/assets/news/16june/5.jpeg";

import june_19_21_1 from "../images/assets/news/19june/1.jpeg";
import june_19_21_2 from "../images/assets/news/19june/2.jpeg";
import june_19_21_3 from "../images/assets/news/19june/3.jpeg";
import june_19_21_4 from "../images/assets/news/19june/4.jpeg";

import june_21_21_1 from "../images/assets/news/21june/1.jpeg";
import june_21_21_2 from "../images/assets/news/21june/2.jpeg";
import june_21_21_3 from "../images/assets/news/21june/3.jpeg";

import june_21_21_2_1 from "../images/assets/news/21june2/1.jpeg";
import june_21_21_2_2 from "../images/assets/news/21june2/2.jpeg";
import june_21_21_2_3 from "../images/assets/news/21june2/3.jpeg";

import june_29_21_1 from "../images/assets/news/29june/1.jpeg";
import june_29_21_2 from "../images/assets/news/29june/2.jpeg";
import june_29_21_3 from "../images/assets/news/29june/3.jpeg";
import june_29_21_4 from "../images/assets/news/29june/4.jpeg";

import july_01_21_1 from "../images/assets/news/01july/1.jpeg";
import july_01_21_2 from "../images/assets/news/01july/2.jpeg";
import july_01_21_3 from "../images/assets/news/01july/3.jpeg";
import july_01_21_4 from "../images/assets/news/01july/4.jpeg";

import july_02_21_1 from "../images/assets/news/02july/1.jpeg";
import july_02_21_2 from "../images/assets/news/02july/2.jpeg";
import july_02_21_3 from "../images/assets/news/02july/3.jpeg";
import july_02_21_4 from "../images/assets/news/02july/4.jpeg";

import july_02_21_2_1 from "../images/assets/news/02july2/1.jpeg";
import july_02_21_2_2 from "../images/assets/news/02july2/2.jpeg";
import july_02_21_2_3 from "../images/assets/news/02july2/3.jpeg";
import july_02_21_2_4 from "../images/assets/news/02july2/4.jpeg";

import july_09_21_1 from "../images/assets/news/09july2/1.jpeg";
import july_09_21_2 from "../images/assets/news/09july2/2.jpeg";
import july_09_21_3 from "../images/assets/news/09july2/3.jpeg";
import july_09_21_4 from "../images/assets/news/09july2/4.jpeg";

import july_16_21_1 from "../images/assets/news/16july/1.jpeg";
import july_16_21_2 from "../images/assets/news/16july/2.jpeg";
import july_16_21_3 from "../images/assets/news/16july/3.jpeg";
import july_16_21_4 from "../images/assets/news/16july/4.jpeg";
import july_16_21_5 from "../images/assets/news/16july/5.jpeg";

import july_16_21_2_1 from "../images/assets/news/16july2/1.jpeg";
import july_16_21_2_2 from "../images/assets/news/16july2/2.jpeg";
import july_16_21_2_3 from "../images/assets/news/16july2/3.jpeg";
import july_16_21_2_4 from "../images/assets/news/16july2/4.jpeg";
import july_16_21_2_5 from "../images/assets/news/16july2/5.jpeg";

import july_17_21_1 from "../images/assets/news/17july/1.jpeg";
import july_17_21_2 from "../images/assets/news/17july/2.jpeg";
import july_17_21_3 from "../images/assets/news/17july/3.jpeg";
import july_17_21_4 from "../images/assets/news/17july/4.jpeg";

import july_22_21_1 from "../images/assets/news/22july/1.jpeg";
import july_22_21_2 from "../images/assets/news/22july/2.jpeg";
import july_22_21_3 from "../images/assets/news/22july/3.jpeg";
import july_22_21_4 from "../images/assets/news/22july/4.jpeg";

import july_22_21_2_1 from "../images/assets/news/22july2/1.jpeg";
import july_22_21_2_2 from "../images/assets/news/22july2/2.jpeg";
import july_22_21_2_3 from "../images/assets/news/22july2/3.jpeg";
import july_22_21_2_4 from "../images/assets/news/22july2/4.jpeg";

import july_24_21_1 from "../images/assets/news/24july/1.jpeg";
import july_24_21_2 from "../images/assets/news/24july/2.jpeg";
import july_24_21_3 from "../images/assets/news/24july/3.jpeg";
import july_24_21_4 from "../images/assets/news/24july/4.jpeg";

import july_24_21_2_1 from "../images/assets/news/24july2/1.jpeg";
import july_24_21_2_2 from "../images/assets/news/24july2/2.jpeg";
import july_24_21_2_3 from "../images/assets/news/24july2/3.jpeg";
import july_24_21_2_4 from "../images/assets/news/24july2/4.jpeg";

import july_24_21_3_1 from "../images/assets/news/24july3/1.jpeg";
import july_24_21_3_2 from "../images/assets/news/24july3/2.jpeg";
import july_24_21_3_3 from "../images/assets/news/24july3/3.jpeg";
import july_24_21_3_4 from "../images/assets/news/24july3/4.jpeg";

import july_28_21_1 from "../images/assets/news/28july/1.jpeg";
import july_28_21_2 from "../images/assets/news/28july/2.jpeg";
import july_28_21_3 from "../images/assets/news/28july/3.jpeg";
import july_28_21_4 from "../images/assets/news/28july/4.jpeg";

import july_29_21_1 from "../images/assets/news/29july/1.jpeg";
import july_29_21_2 from "../images/assets/news/29july/2.jpeg";
import july_29_21_3 from "../images/assets/news/29july/3.jpeg";
import july_29_21_4 from "../images/assets/news/29july/4.jpeg";
import july_29_21_5 from "../images/assets/news/29july/5.jpeg";

import july_31_21_1 from "../images/assets/news/31july/1.jpeg";
import july_31_21_2 from "../images/assets/news/31july/2.jpeg";
import july_31_21_3 from "../images/assets/news/31july/3.jpeg";
import july_31_21_4 from "../images/assets/news/31july/4.jpeg";
import july_31_21_5 from "../images/assets/news/29july/5.jpeg";

import july_31_21_2_1 from "../images/assets/news/31july2/1.jpeg";
import july_31_21_2_2 from "../images/assets/news/31july2/2.jpeg";
import july_31_21_2_3 from "../images/assets/news/31july2/3.jpeg";
import july_31_21_2_4 from "../images/assets/news/31july2/4.jpeg";
import july_31_21_2_5 from "../images/assets/news/31july2/5.jpeg";

import aug_03_21_1 from "../images/assets/news/03aug/1.jpeg";
import aug_03_21_2 from "../images/assets/news/03aug/2.jpeg";
import aug_03_21_3 from "../images/assets/news/03aug/3.jpeg";
import aug_03_21_4 from "../images/assets/news/03aug/4.jpeg";

import aug_04_21_1 from "../images/assets/news/04aug/1.jpeg";
import aug_04_21_2 from "../images/assets/news/04aug/2.jpeg";
import aug_04_21_3 from "../images/assets/news/04aug/3.jpeg";
import aug_04_21_4 from "../images/assets/news/04aug/4.jpeg";

import aug_07_21_1 from "../images/assets/news/07aug/1.jpeg";
import aug_07_21_2 from "../images/assets/news/07aug/2.jpeg";
import aug_07_21_3 from "../images/assets/news/07aug/3.jpeg";
import aug_07_21_4 from "../images/assets/news/07aug/4.jpeg";
import aug_07_21_5 from "../images/assets/news/07aug/5.jpeg";

import aug_15_21 from "../images/assets/news/15_aug_2021.png";

import aug_19_21_1 from "../images/assets/news/19aug/1.jpeg";
import aug_19_21_2 from "../images/assets/news/19aug/2.jpeg";
import aug_19_21_3 from "../images/assets/news/19aug/3.jpeg";
import aug_19_21_4 from "../images/assets/news/19aug/4.jpeg";
import aug_19_21_5 from "../images/assets/news/19aug/5.jpeg";

import aug_22_21_1 from "../images/assets/news/22aug/1.jpeg";
import aug_22_21_2 from "../images/assets/news/22aug/2.jpeg";
import aug_22_21_3 from "../images/assets/news/22aug/3.jpeg";
import aug_22_21_4 from "../images/assets/news/22aug/4.jpeg";
import aug_22_21_5 from "../images/assets/news/22aug/5.jpeg";

import aug_24_21_1 from "../images/assets/news/24aug/1.jpeg";
import aug_24_21_2 from "../images/assets/news/24aug/2.jpeg";
import aug_24_21_3 from "../images/assets/news/24aug/3.jpeg";
import aug_24_21_4 from "../images/assets/news/24aug/4.jpeg";
import aug_24_21_5 from "../images/assets/news/24aug/5.jpeg";

import aug_24_21_2_1 from "../images/assets/news/24aug2/1.jpeg";
import aug_24_21_2_2 from "../images/assets/news/24aug2/2.jpeg";
import aug_24_21_2_3 from "../images/assets/news/24aug2/3.jpeg";
import aug_24_21_2_4 from "../images/assets/news/24aug2/4.jpeg";

import aug_25_21_1 from "../images/assets/news/25aug/1.jpeg";
import aug_25_21_2 from "../images/assets/news/25aug/2.jpeg";
import aug_25_21_3 from "../images/assets/news/25aug/3.jpeg";
import aug_25_21_4 from "../images/assets/news/25aug/4.jpeg";
import aug_25_21_5 from "../images/assets/news/25aug/5.jpeg";

import aug_29_21 from "../images/assets/news/29_aug_21.png";

import aug_30_21_1 from "../images/assets/news/30aug/1.jpeg";
import aug_30_21_2 from "../images/assets/news/30aug/2.jpeg";
import aug_30_21_3 from "../images/assets/news/30aug/3.jpeg";
import aug_30_21_4 from "../images/assets/news/30aug/4.jpeg";

import aug_30_21_2_1 from "../images/assets/news/30aug2/1.jpeg";
import aug_30_21_2_2 from "../images/assets/news/30aug2/2.jpeg";
import aug_30_21_2_3 from "../images/assets/news/30aug2/3.jpeg";
import aug_30_21_2_4 from "../images/assets/news/30aug2/4.jpeg";
import aug_30_21_2_5 from "../images/assets/news/30aug2/5.jpeg";
import aug_30_21_2_6 from "../images/assets/news/30aug2/6.jpeg";

import sep_05_21_1 from "../images/assets/news/05sep/1.jpeg";
import sep_05_21_2 from "../images/assets/news/05sep/2.jpeg";

import sep_05_21_2_1 from "../images/assets/news/05sep2/1.jpeg";
import sep_05_21_2_2 from "../images/assets/news/05sep2/2.jpeg";
import sep_05_21_2_3 from "../images/assets/news/05sep2/3.jpeg";
import sep_05_21_2_4 from "../images/assets/news/05sep2/4.jpeg";
import sep_05_21_2_5 from "../images/assets/news/05sep2/5.jpeg";

import sep_10_21_1 from "../images/assets/news/10sep/1.jpeg";
import sep_10_21_2 from "../images/assets/news/10sep/2.jpeg";
import sep_10_21_3 from "../images/assets/news/10sep/3.jpeg";
import sep_10_21_4 from "../images/assets/news/10sep/4.jpeg";
import sep_10_21_5 from "../images/assets/news/10sep/5.jpeg";

import sep_14_21_1 from "../images/assets/news/14sep/1.jpeg";
import sep_14_21_2 from "../images/assets/news/14sep/2.jpeg";
import sep_14_21_3 from "../images/assets/news/14sep/3.jpeg";
import sep_14_21_4 from "../images/assets/news/14sep/4.jpeg";
import sep_14_21_5 from "../images/assets/news/14sep/5.jpeg";

import sep_17_21_1 from "../images/assets/news/17sep/1.jpeg";
import sep_17_21_2 from "../images/assets/news/17sep/2.jpeg";
import sep_17_21_3 from "../images/assets/news/17sep/3.jpeg";
import sep_17_21_4 from "../images/assets/news/17sep/4.jpeg";
import sep_17_21_5 from "../images/assets/news/17sep/5.jpeg";

import sep_17_21_2_1 from "../images/assets/news/17sep2/1.jpeg";
import sep_17_21_2_2 from "../images/assets/news/17sep2/2.jpeg";
import sep_17_21_2_3 from "../images/assets/news/17sep2/3.jpeg";
import sep_17_21_2_4 from "../images/assets/news/17sep2/4.jpeg";
import sep_17_21_2_5 from "../images/assets/news/17sep2/5.jpeg";

import sep_18_21_1 from "../images/assets/news/18sep/1.jpeg";
import sep_18_21_2 from "../images/assets/news/18sep/2.jpeg";
import sep_18_21_3 from "../images/assets/news/18sep/3.jpeg";
import sep_18_21_4 from "../images/assets/news/18sep/4.jpeg";

import sep_24_21_1 from "../images/assets/news/24sep/1.jpeg";
import sep_24_21_2 from "../images/assets/news/24sep/2.jpeg";
import sep_24_21_3 from "../images/assets/news/24sep/3.jpeg";
import sep_24_21_4 from "../images/assets/news/24sep/4.jpeg";
import sep_24_21_5 from "../images/assets/news/24sep/5.jpeg";

import oct_01_21_1 from "../images/assets/news/01oct/1.jpeg";
import oct_01_21_2 from "../images/assets/news/01oct/2.jpeg";
import oct_01_21_3 from "../images/assets/news/01oct/3.jpeg";
import oct_01_21_4 from "../images/assets/news/01oct/4.jpeg";
import oct_01_21_5 from "../images/assets/news/01oct/5.jpeg";

import oct_03_21_1 from "../images/assets/news/03oct/1.jpeg";
import oct_03_21_2 from "../images/assets/news/03oct/2.jpeg";
import oct_03_21_3 from "../images/assets/news/03oct/3.jpeg";
import oct_03_21_4 from "../images/assets/news/03oct/4.jpeg";

import oct_03_21_2_1 from "../images/assets/news/03oct2/1.jpeg";
import oct_03_21_2_2 from "../images/assets/news/03oct2/2.jpeg";
import oct_03_21_2_3 from "../images/assets/news/03oct2/3.jpeg";
import oct_03_21_2_4 from "../images/assets/news/03oct2/4.jpeg";

import oct_06_21_1 from "../images/assets/news/06oct/1.jpeg";
import oct_06_21_2 from "../images/assets/news/06oct/2.jpeg";
import oct_06_21_3 from "../images/assets/news/06oct/3.jpeg";
import oct_06_21_4 from "../images/assets/news/06oct/4.jpeg";
import oct_06_21_5 from "../images/assets/news/06oct/5.jpeg";

import oct_06_21_2_1 from "../images/assets/news/06oct2/1.jpeg";
import oct_06_21_2_2 from "../images/assets/news/06oct2/2.jpeg";
import oct_06_21_2_3 from "../images/assets/news/06oct2/3.jpeg";
import oct_06_21_2_4 from "../images/assets/news/06oct2/4.jpeg";
import oct_06_21_2_5 from "../images/assets/news/06oct2/5.jpeg";

import oct_07_21_1 from "../images/assets/news/oct07/1.jpeg";
import oct_07_21_2 from "../images/assets/news/oct07/2.jpeg";
import oct_07_21_3 from "../images/assets/news/oct07/3.jpeg";
import oct_07_21_4 from "../images/assets/news/oct07/4.jpeg";
import oct_07_21_5 from "../images/assets/news/oct07/5.jpeg";

import oct_08_21_1 from "../images/assets/news/08oct/1.jpeg";
import oct_08_21_2 from "../images/assets/news/08oct/2.jpeg";
import oct_08_21_3 from "../images/assets/news/08oct/3.jpeg";
import oct_08_21_4 from "../images/assets/news/08oct/4.jpeg";

import oct_11_21_1 from "../images/assets/news/11oct/1.jpeg";
import oct_11_21_2 from "../images/assets/news/11oct/2.jpeg";
import oct_11_21_3 from "../images/assets/news/11oct/3.jpeg";
import oct_11_21_4 from "../images/assets/news/11oct/4.jpeg";
import oct_11_21_5 from "../images/assets/news/11oct/5.jpeg";

import oct_11_21_2_1 from "../images/assets/news/11oct2/1.jpeg";
import oct_11_21_2_2 from "../images/assets/news/11oct2/2.jpeg";
import oct_11_21_2_3 from "../images/assets/news/11oct2/3.jpeg";
import oct_11_21_2_4 from "../images/assets/news/11oct2/4.jpeg";

import oct_14_21_1 from "../images/assets/news/14oct/pic1.jpeg";
import oct_14_21_2 from "../images/assets/news/14oct/pic2.jpeg";
import oct_14_21_3 from "../images/assets/news/14oct/pic3.jpeg";
import oct_14_21_4 from "../images/assets/news/14oct/pic4.jpeg";
import oct_14_21_5 from "../images/assets/news/14oct/pic5.jpeg";
import oct_14_21_6 from "../images/assets/news/14oct/pic6.jpeg";
import oct_14_21_7 from "../images/assets/news/14oct/pic7.jpeg";

import oct_15_21_1 from "../images/assets/news/15oct/1.jpeg";
import oct_15_21_2 from "../images/assets/news/15oct/2.jpeg";
import oct_15_21_3 from "../images/assets/news/15oct/3.jpeg";
import oct_15_21_4 from "../images/assets/news/15oct/4.jpeg";
import oct_15_21_5 from "../images/assets/news/15oct/5.jpeg";

import oct_23_21_1 from "../images/assets/news/23oct/1.jpeg";
import oct_23_21_2 from "../images/assets/news/23oct/2.jpeg";
import oct_23_21_3 from "../images/assets/news/23oct/3.jpeg";
import oct_23_21_4 from "../images/assets/news/23oct/4.jpeg";
import oct_23_21_5 from "../images/assets/news/23oct/5.jpeg";

import oct_24_21_1 from "../images/assets/news/24oct/1.jpeg";
import oct_24_21_2 from "../images/assets/news/24oct/2.jpeg";
import oct_24_21_3 from "../images/assets/news/24oct/3.jpeg";
import oct_24_21_4 from "../images/assets/news/24oct/4.jpeg";
import oct_24_21_5 from "../images/assets/news/24oct/5.jpeg";

import oct_29_21_1 from "../images/assets/news/29oct/1.jpeg";
import oct_29_21_2 from "../images/assets/news/29oct/2.jpeg";
import oct_29_21_3 from "../images/assets/news/29oct/3.jpeg";

import oct_31_21_1 from "../images/assets/news/31oct/1.jpeg";
import oct_31_21_2 from "../images/assets/news/31oct/2.jpeg";
import oct_31_21_3 from "../images/assets/news/31oct/3.jpeg";
import oct_31_21_4 from "../images/assets/news/31oct/4.jpeg";
import oct_31_21_5 from "../images/assets/news/31oct/5.jpeg";

import nov_01_21_1 from "../images/assets/news/nov1/1.jpeg";
import nov_01_21_2 from "../images/assets/news/nov1/2.jpeg";
import nov_01_21_3 from "../images/assets/news/nov1/3.jpeg";

import nov_04_21_1 from "../images/assets/news/nov2/1.jpeg";
import nov_04_21_2 from "../images/assets/news/nov2/2.jpeg";
import nov_04_21_3 from "../images/assets/news/nov2/3.jpeg";
import nov_04_21_4 from "../images/assets/news/nov2/4.jpeg";
import nov_04_21_5 from "../images/assets/news/nov2/5.jpeg";

import nov_08_21_1 from "../images/assets/news/nov8/1.jpeg";
import nov_08_21_2 from "../images/assets/news/nov8/2.jpeg";
import nov_08_21_3 from "../images/assets/news/nov8/3.jpeg";
import nov_08_21_4 from "../images/assets/news/nov8/4.jpeg";
import nov_08_21_5 from "../images/assets/news/nov8/5.jpeg";

import nov_09_21_1 from "../images/assets/news/nov9/1.jpeg";
import nov_09_21_2 from "../images/assets/news/nov9/2.jpeg";
import nov_09_21_3 from "../images/assets/news/nov9/3.jpeg";
import nov_09_21_4 from "../images/assets/news/nov9/4.jpeg";
import nov_09_21_5 from "../images/assets/news/nov9/5.jpeg";

import nov_11_21_1 from "../images/assets/news/nov11/1.jpeg";
import nov_11_21_2 from "../images/assets/news/nov11/2.jpeg";
import nov_11_21_3 from "../images/assets/news/nov11/3.jpeg";
import nov_11_21_4 from "../images/assets/news/nov11/4.jpeg";
import nov_11_21_5 from "../images/assets/news/nov11/5.jpeg";

import nov_13_21_1 from "../images/assets/news/nov13/1.jpeg";
import nov_13_21_2 from "../images/assets/news/nov13/2.jpeg";
import nov_13_21_3 from "../images/assets/news/nov13/3.jpeg";
import nov_13_21_4 from "../images/assets/news/nov13/4.jpeg";
import nov_13_21_5 from "../images/assets/news/nov13/5.jpeg";

import nov_14_21_1 from "../images/assets/news/nov14/1.jpeg";
import nov_14_21_2 from "../images/assets/news/nov14/2.jpeg";
import nov_14_21_3 from "../images/assets/news/nov14/3.jpeg";
import nov_14_21_4 from "../images/assets/news/nov14/4.jpeg";
import nov_14_21_5 from "../images/assets/news/nov14/5.jpeg";

import nov_17_21_1 from "../images/assets/news/nov17/1.jpeg";
import nov_17_21_2 from "../images/assets/news/nov17/2.jpeg";
import nov_17_21_3 from "../images/assets/news/nov17/3.jpeg";
import nov_17_21_4 from "../images/assets/news/nov17/4.jpeg";
import nov_17_21_5 from "../images/assets/news/nov17/5.jpeg";

import nov_18_21_1 from "../images/assets/news/nov18/1.jpeg";
import nov_18_21_2 from "../images/assets/news/nov18/2.jpeg";
import nov_18_21_3 from "../images/assets/news/nov18/3.jpeg";
import nov_18_21_4 from "../images/assets/news/nov18/4.jpeg";
import nov_18_21_5 from "../images/assets/news/nov18/5.jpeg";

import nov_19_21_1 from "../images/assets/news/nov19/1.jpeg";
import nov_19_21_2 from "../images/assets/news/nov19/2.jpeg";
import nov_19_21_3 from "../images/assets/news/nov19/3.jpeg";
import nov_19_21_4 from "../images/assets/news/nov19/4.jpeg";
import nov_19_21_5 from "../images/assets/news/nov19/5.jpeg";

import nov_24_21_1 from "../images/assets/news/nov24/1.jpeg";
import nov_24_21_2 from "../images/assets/news/nov24/2.jpeg";
import nov_24_21_3 from "../images/assets/news/nov24/3.jpeg";
import nov_24_21_4 from "../images/assets/news/nov24/4.jpeg";
import nov_24_21_5 from "../images/assets/news/nov24/5.jpeg";

import nov_25_21_1 from "../images/assets/news/nov25/1.jpeg";
import nov_25_21_2 from "../images/assets/news/nov25/2.jpeg";
import nov_25_21_3 from "../images/assets/news/nov25/3.jpeg";
import nov_25_21_4 from "../images/assets/news/nov25/4.jpeg";
import nov_25_21_5 from "../images/assets/news/nov25/5.jpeg";

import nov_26_21_1 from "../images/assets/news/nov26/1.jpeg";
import nov_26_21_2 from "../images/assets/news/nov26/2.jpeg";
import nov_26_21_3 from "../images/assets/news/nov26/3.jpeg";
import nov_26_21_4 from "../images/assets/news/nov26/4.jpeg";
import nov_26_21_5 from "../images/assets/news/nov26/5.jpeg";

import nov_30_21_1 from "../images/assets/news/nov30/1.jpeg";
import nov_30_21_2 from "../images/assets/news/nov30/2.jpeg";
import nov_30_21_3 from "../images/assets/news/nov30/3.jpeg";
import nov_30_21_4 from "../images/assets/news/nov30/4.jpeg";
import nov_30_21_5 from "../images/assets/news/nov30/5.jpeg";

import dec_03_21_1 from "../images/assets/news/dec3/1.jpeg";
import dec_03_21_2 from "../images/assets/news/dec3/2.jpeg";
import dec_03_21_3 from "../images/assets/news/dec3/3.jpeg";
import dec_03_21_4 from "../images/assets/news/dec3/4.jpeg";
import dec_03_21_5 from "../images/assets/news/dec3/5.jpeg";

import dec_04_21_1 from "../images/assets/news/dec4/1.jpeg";
import dec_04_21_2 from "../images/assets/news/dec4/2.jpeg";
import dec_04_21_3 from "../images/assets/news/dec4/3.jpeg";
import dec_04_21_4 from "../images/assets/news/dec4/4.jpeg";
import dec_04_21_5 from "../images/assets/news/dec4/5.jpeg";

import dec_07_21_1 from "../images/assets/news/dec7/1.jpeg";
import dec_07_21_2 from "../images/assets/news/dec7/2.jpeg";
import dec_07_21_3 from "../images/assets/news/dec7/3.jpeg";
import dec_07_21_4 from "../images/assets/news/dec7/4.jpeg";
import dec_07_21_5 from "../images/assets/news/dec7/5.jpeg";

import dec_09_21_1 from "../images/assets/news/dec8/1.jpeg";
import dec_09_21_2 from "../images/assets/news/dec8/2.jpeg";
import dec_09_21_3 from "../images/assets/news/dec8/3.jpeg";
import dec_09_21_4 from "../images/assets/news/dec8/4.jpeg";
import dec_09_21_5 from "../images/assets/news/dec8/5.jpeg";

import dec_11_21_1 from "../images/assets/news/dec11/1.jpeg";
import dec_11_21_2 from "../images/assets/news/dec11/2.jpeg";
import dec_11_21_3 from "../images/assets/news/dec11/3.jpeg";
import dec_11_21_4 from "../images/assets/news/dec11/4.jpeg";
import dec_11_21_5 from "../images/assets/news/dec11/5.jpeg";

import dec_16_21_1 from "../images/assets/news/dec16/1.jpeg";
import dec_16_21_2 from "../images/assets/news/dec16/2.jpeg";
import dec_16_21_3 from "../images/assets/news/dec16/3.jpeg";
import dec_16_21_4 from "../images/assets/news/dec16/4.jpeg";
import dec_16_21_5 from "../images/assets/news/dec16/5.jpeg";

import dec_24_21_1 from "../images/assets/news/dec24/1.jpeg";
import dec_24_21_2 from "../images/assets/news/dec24/2.jpeg";
import dec_24_21_3 from "../images/assets/news/dec24/3.jpeg";
import dec_24_21_4 from "../images/assets/news/dec24/4.jpeg";
import dec_24_21_5 from "../images/assets/news/dec24/5.jpeg";

import dec_24_21_2_1 from "../images/assets/news/dec24-2/1.jpeg";
import dec_24_21_2_2 from "../images/assets/news/dec24-2/2.jpeg";
import dec_24_21_2_3 from "../images/assets/news/dec24-2/3.jpeg";
import dec_24_21_2_4 from "../images/assets/news/dec24-2/4.jpeg";
import dec_24_21_2_5 from "../images/assets/news/dec24-2/5.jpeg";

import dec_25_21_1 from "../images/assets/news/dec25/1.jpeg";
import dec_25_21_2 from "../images/assets/news/dec25/2.jpeg";
import dec_25_21_3 from "../images/assets/news/dec25/3.jpeg";
import dec_25_21_4 from "../images/assets/news/dec25/4.jpeg";
import dec_25_21_5 from "../images/assets/news/dec25/5.jpeg";

import dec_25_21_2_1 from "../images/assets/news/dec25-2/1.jpeg";
import dec_25_21_2_2 from "../images/assets/news/dec25-2/2.jpeg";
import dec_25_21_2_3 from "../images/assets/news/dec25-2/3.jpeg";
import dec_25_21_2_4 from "../images/assets/news/dec25-2/4.jpeg";
import dec_25_21_2_5 from "../images/assets/news/dec25-2/5.jpeg";

import dec_29_21_1 from "../images/assets/news/dec29/1.jpeg";
import dec_29_21_2 from "../images/assets/news/dec29/2.jpeg";
import dec_29_21_3 from "../images/assets/news/dec29/3.jpeg";
import dec_29_21_4 from "../images/assets/news/dec29/4.jpeg";
import dec_29_21_5 from "../images/assets/news/dec29/5.jpeg";

import july_31_21 from "../images/assets/news/31_july_2021.jpeg";

import may_08_20_message from "../images/assets/news/messagefromtheprincipal.png";

import jan_01_21 from "../images/assets/news/26_01_2021.png";

const ews1 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews1.jpeg";
const ews2 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews2.jpeg";
const ews3 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews3.jpeg";
const ews4 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews4.jpeg";
const ews5 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews5.jpeg";
const ews6 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews6.jpeg";
const ews7 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews7.jpeg";
const ews8 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews8.jpeg";
const ews9 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews9.jpeg";
const ews10 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews10.jpeg";
const ews11 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews11.jpeg";
const ews12 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews12.jpeg";
const ews13 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews13.jpeg";
const ews14 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews14.jpeg";
const ews15 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews15.jpeg";
const ews16 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews16.jpeg";
const ews17 = "https://chinmayavvdelhi.ac.in/assets/images/ews/ews17.jpeg";

const june_21_20_1 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_1.png";
const june_21_20_2 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_2.png";
const june_21_20_3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_3.png";
const june_21_20_4 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_4.png";
const june_21_20_5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_5.png";
const june_21_20_6 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_6.png";
const june_21_20_7 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_7.png";
const june_21_20_8 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_8.png";
const june_21_20_9 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_9.png";
const june_21_20_10 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_10.png";
const june_21_20_11 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/21_june_11.png";

const may_02_20_1 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Healthsetgo_1.png";
const may_02_20_2 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Healthsetgo_2.png";
const may_02_20_3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Healthsetgo_3.png";
const may_02_20_4 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Healthsetgo_4.png";
const may_02_20_5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Healthsetgo_5.png";
const may_02_20_6 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Healthsetgo_6.png";

const may_01_20_1 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Fun_crafy_1.png";
const may_01_20_2 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Fun_crafy_2.png";
const may_01_20_3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Fun_crafy_3.png";
const may_01_20_4 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Fun_crafy_4.png";
const may_01_20_5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Fun_crafy_5.png";

const april_30_20_1 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Brushing_1.png";
const april_30_20_2 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Brushing_2.png";
const april_30_20_3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Brushing_3.png";
const april_30_20_4 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Brushing_4.png";
const april_30_20_5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Brushing_5.png";
const april_30_20_6 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Brushing_6.png";

const april_27_20_1 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Earth_day_1.png";
const april_27_20_2 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Earth_day_2.png";
const april_27_20_3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Earth_day_3.png";
const april_27_20_4 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Earth_day_4.png";
const april_27_20_5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Earth_day_5.png";

const april_24_20_1 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/HRed_day_1.png";
const april_24_20_2 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/HRed_day_2.png";
const april_24_20_3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/HRed_day_3.png";
const april_24_20_4 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/HRed_day_4.png";
const april_24_20_5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/HRed_day_5.png";
const april_24_20_6 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/HRed_day_6.png";

const april_20_20_1 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Chinamaya_1.png";
const april_20_20_2 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Chinamaya_2.png";
const april_20_20_3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Chinamaya_3.png";
const april_20_20_4 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Chinamaya_4.png";
const april_20_20_5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Chinamaya_5.png";
const april_20_20_6 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Chinamaya_6.png";
const april_20_20_7 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Chinamaya_7.png";
const april_20_20_8 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Chinamaya_8.png";

const april_11_20_1 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Action_4_mask_1.png";
const april_11_20_2 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Action_4_mask_2.png";
const april_11_20_3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Action_4_mask_3.png";
const april_11_20_4 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Action_4_mask_4.png";
const april_11_20_5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Action_4_mask_5.png";
const april_11_20_6 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/eventsImages/Action_4_mask_6.png";

const data = [
  {
    title: "CLASS X PRESENTATION",
    text: `Class X worked together as a team and presented a melange of thoughts, ideas, and expression (dance and music performance, skit) in their Class Presentation held on 30th September 2022 wherein parents were invited to witness the efforts of their wards.<br/><br/>
    The theme of the programme - Women Empowerment was brought out through the skit “Samar Shesh Hai”. The skit delivered the strong message that empowering women is essential to the health and social development of the families, communities and countries.
    <br/><br/>
    Visual Art and ATL work display were also much appreciated by the parents.`,
    fullDate: "03 October, 2022",
    d: "03",
    m: "Oct",
    y: "2022",
    images: ["https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/01.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/02.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/03.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/04.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/05.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/06.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/07.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/08.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/09.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/10.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/11.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/12.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/13.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/14.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/15.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/16.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/17.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/class-x-presentation/18.JPG",
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "GANDHI JAYANTI CELEBRATIONS",
    text: `Guided by this inspiring quote, Chinmaya Vidyalaya, New Delhi, celebrated the birth anniversary of the Father of the Nation- Mohandas Karamchand Gandhi by conducting a special assembly and a few activities that enlightened the students with his teachings.
<br/><br/>
    The students of Class VIII organised a special assembly wherein they enacted the famous quotes of Mahatma Gandhi and presented their practical application in real life situations. The soulful Bhajan 'Vaishnav Janato' infused all with deep respect for Mahatma Gandhi who always preached non-violence and love for all beings. The Pre-Primary Department imbibed the spirit of cleanliness by organizing Swachh Bharat Campaign wherein they cleaned their classrooms and playground with the help of their teachers. It was followed by a free hand drawing and a bhajan. A follow-up activity was also given to them in which they cleaned their study tables at home and organised their play area. The Primary students enjoyed singing the bhajan 'Raghupati Raghav RajaRam'. The assembly culminated with the enactment of Dandi March in which a group of students led by Gandhiji visited the corridors and classrooms to spread the teachings of Mahatma Gandhi.`,
    fullDate: "02 October, 2022",
    d: "02",
    m: "Oct",
    y: "2022",
    images: ["https://chinmayavvdelhi.ac.in/assets/images/gandhi-jayanti/01.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/gandhi-jayanti/02.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/gandhi-jayanti/03.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/gandhi-jayanti/04.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/gandhi-jayanti/05.JPG",
      "https://chinmayavvdelhi.ac.in/assets/images/gandhi-jayanti/06.JPG",

    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "VEDIC MATHEMATICS WORKSHOP",
    text: `Chinmaya Vidyalaya, New Delhi, in association with Pratham Institute, organised an enriching Vedic Mathematics Workshop for the students of classes VI-VIII. The workshop aimed at sharing the secrets of Vedic Maths for easier mathematical calculations. The session highlighted the importance of Vedic Maths in Indian Culture and introduced it as an effective way of making quicker calculations in real life. The workshop motivated the students to incorporate the intellectual excellence of our rich ancient culture to make lives comfortable.`,
    fullDate: "20 September, 2022",
    d: "20",
    m: "Sep",
    y: "2022",
    images: [
      "https://chinmayavvdelhi.ac.in/assets/images/Maths-Workshop1.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/Maths-Workshop2.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/Maths-Workshop3.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/Maths-Workshop4.jpg",
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "NATIONAL SPORTS DAY 2022",
    text: `Chinmaya Vidyalaya, New Delhi celebrated National Sports Day on 29th August 2022 with zest and zeal. The day was observed to encourage the students to enthusiastically participate in sports and games. The Pre- primary students were shown a digital presentation on the importance of sports followed by their active engagement in fun-filled games. The students of Primary Department performed a set of physical exercises and aerobics that took them a step forward towards their physical fitness. An informative presentation and slogans on sports enlightened them about various types of sports and famous sports personalities.
    <br/><br/>
    The students of the Senior Department organised a special assembly to celebrate the spirit of sportsmanship. They energetically participated in head-to-toe physical exercises.
    <br/><br/>
    The celebrations of the day motivated the students to work relentlessly towards physical and mental fitness and agility.`,
    fullDate: "29 August, 2022",
    d: "29",
    m: "Aug",
    y: "2022",
    images: [
      "https://chinmayavvdelhi.ac.in/assets/images/sports-day/sports-day-2022-1.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/sports-day/sports-day-2022-2.jpg",
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Tablet Distribution Programme For EWS Students August 2022",
    text: `To facilitate hybrid education, Chinmaya Mission distributed tablets to about 109 students of the Primary Department of Chinmaya Vidyalaya from the economically weaker section. The Director of Education, GNCT of Delhi, Shri Himanshu Gupta kindly consented to grace the event as the chief guest. In his address, he stated that a delicate balance should be maintained between online and offline education and gadgets should be used to enhance the Teaching-Learning process. He also emphasized on aligning the mind and body to derive the most out of life. The Guest of Honour- Deputy Director of Education, GNCT of Delhi, Dr Sanjay Chaturvedi and distinguished guest- Deputy Director of Education, GNCT of Delhi, Shri Sushil Kumar also graced the occasion. Trustee, Delhi Chinmaya Sewa Trust and a member of Chinmaya Vidyalaya Managing Committee, Shri Jujhar Singh, the Manager, Ms. Shibani Khorana and the Principal, Ms Archana Soni were also present for the programme. The eminent guests motivated the audience to follow their dreams based on the real essence of education, which is, value creation.`,
    fullDate: "23 August, 2022",
    d: "23",
    m: "Aug",
    y: "2022",
    images: [
      "https://chinmayavvdelhi.ac.in/assets/images/ews/table-aug-2022-img1.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/ews/table-aug-2022-img2.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/ews/table-aug-2022-img3.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/ews/table-aug-2022-img4.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/ews/table-aug-2022-img5.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/ews/table-aug-2022-img6.jpg",
      "https://chinmayavvdelhi.ac.in/assets/images/ews/table-aug-2022-img7.jpg",
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },


  {
    title: "25th Inter School Geeta Chanting Competition",
    text: "The 25th Inter School Geeta Chanting Competition was held online by Chinmaya Vidyalaya, New Delhi from 22nd – 24th December 2021.The three-day event witnessed an overwhelming participation of 412 students from 40 schools across Delhi and NCR from classes Nursery- X. They enthusiastically chanted the verses from Chapter 2 - Sankhya Yog that condenses the teachings of the entire Bhagwad Geeta.<br>The three day ceremonious event culminated with the Felicitation Ceremony held on 28th December 2021, announcing the results. The overall trophy was won by Chinmaya Vidyalaya, New Delhi. Being the host school, the trophy was handed over to Amity International School, Noida, which stood second.<br>Such events make life a celebration and ensure a perennial flow of absolute and imperishable knowledge.",
    fullDate: "29 December, 2021",
    d: "29",
    m: "Dec",
    y: "2021",
    images: [dec_29_21_1, dec_29_21_2, dec_29_21_3, dec_29_21_4, dec_29_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Christmas Celebration - Pre Primary",
    text: "Christmas is a festival of joy, sharing and giving. To spread this message amongst one and all, the students of Pre-Primary Department celebrated  Christmas on virtual mode on Friday, 24.12.2021.<br>The students dressed in red and white with santa caps joined the session.<br>To teach the real meaning of ‘CHRISTMAS”,the students were shown stories on “Birth Of Jesus” and “Santa Claus”. A student sung a christmas carol that brought the spirit and gaiety of Christmas alive. It was followed by a foot tapping dance number that filled the hearts of children with cheer and joy.<br>The celebrations ended with a virtual tour to Delhi’s famous churches.<br>All in all, it was a fun- filled session for all.",
    fullDate: "25 December, 2021",
    d: "25",
    m: "Dec",
    y: "2021",
    images: [
      dec_25_21_2_1,
      dec_25_21_2_2,
      dec_25_21_2_3,
      dec_25_21_2_4,
      dec_25_21_2_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Christmas Celebration - Primary",
    text: "*Christmas is the tenderness of the past, courage for the present and hope for the future.* <br>The students of the Primary Department of Chinmaya Vidyalaya, New Delhi, celebrated the festival of Christmas virtually on 24.12.21. The assembly began with the significance of the festival and it was followed by a story that taught the history of the festival. A Christmas Carol enlivened the session and made everyone sing along. A student played a Carol on piano and a dance performance made all the students tap their feet.<br>All in all, it was an interactive, informative and joyful session.",
    fullDate: "25 December, 2021",
    d: "25",
    m: "Dec",
    y: "2021",
    images: [dec_25_21_1, dec_25_21_2, dec_25_21_3, dec_25_21_4, dec_25_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Show and Tell Activity - Nursery",
    text: "Show and Tell activity around Community Helpers theme is both fun and an opportunity to learn about the different helpers. The Pre – Primary Department of Chinmaya Vidyalaya, New Delhi conducted a Show and Tell activity on the theme ‘Community Helpers’. The little ones came dressed up in costumes and enacted their favorite community helper. The activity helped the kids use their creativity and imagination. It also strengthened their communication skills.",
    fullDate: "24 December, 2021",
    d: "24",
    m: "Dec",
    y: "2021",
    images: [
      dec_24_21_2_1,
      dec_24_21_2_2,
      dec_24_21_2_3,
      dec_24_21_2_4,
      dec_24_21_2_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "National Mathematics Day",
    text: "Chinmaya Vidyalaya observed National Mathematics Day on Wednesday, 21st December , to commemorate the birth anniversary of Mathematics genius Sir Srinivas Ramanujan.<br>Classes IX, X and XI put up the show through PPT presentation on the given topics.",
    fullDate: "24 December, 2021",
    d: "24",
    m: "Dec",
    y: "2021",
    images: [dec_24_21_1, dec_24_21_2, dec_24_21_3, dec_24_21_4, dec_24_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Geeta Jayanti and Tapovan Jayanti Celebrations",
    text: "To mark the origin of the holy book 'Bhagavad Geeta', Chinmaya Vidyalaya, New Delhi, celebrated Geeta Jayanti by conducting online special assembly from Classes I to XII. On this day, Tapovan Jayanti was also observed to commemorate the life and teachings of the great sage Tapovan Maharaj, the 'Guru' of Swami Chinmayanandaji.<br>On this day Lord Krishna gave discourse to Arjuna on how to face the challenges of life and perform one's duties with love and affection.<br>The assembly aimed at highlighting the relevance of the teachings of the holy scriptures in the present scenario.",
    fullDate: "16 December, 2021",
    d: "16",
    m: "Dec",
    y: "2021",
    images: [dec_16_21_1, dec_16_21_2, dec_16_21_3, dec_16_21_4, dec_16_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "EXCURSION OF FIRE STATION",
    text: "Chinmaya Vidyalaya, New Delhi organized a virtual tour to a Fire Station for the learners of class Nursery and Prep on 10th December 2021. The little ones were amazed to see the Fire Station virtually. They were enthralled to see a fire truck and the tools used by firemen. Everyone thoroughly enjoyed the excursion. Our little learners were also briefed on wearing a mask and sanitizing their hands. They were also told not to talk to strangers. The session ended with a craft activity which the kids enjoyed a lot.",
    fullDate: "11 December, 2021",
    d: "11",
    m: "Dec",
    y: "2021",
    images: [dec_11_21_1, dec_11_21_2, dec_11_21_3, dec_11_21_4, dec_11_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "The Beauty Of Art",
    text: "I H Art Competition Class I<br>The Beauty Of Art<br>To explore the creative potential of Class I learners, an Inter-House Art Competition was conducted by Chinmaya Vidyalaya, New Delhi on 7th December 2021. The learners showcased their talent by using different types of printing impressions like cotton dabbing, vegetable and ear bud printing to make a beautiful scenery. They participated whole heartedly in the event which brought forth amazing results for everyone to see.",
    fullDate: "09 December, 2021",
    d: "09",
    m: "Dec",
    y: "2021",
    images: [dec_09_21_1, dec_09_21_2, dec_09_21_3, dec_09_21_4, dec_09_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Sewa Session - Pitr Yagna",
    text: "In its pursuit to inculcate the  value of Sewa among the students,the Primary Department of Chinmaya Vidyalaya ,New Delhi conducted a session  to  apprise them about Pitr Yagna and the activity associated with it. <br>Pitr Yagna is a form of sewa which is rendered towards our ancestors .The students pledged to take care of their parents and grandparents and perform good deeds as taught to them by their ancestors.",
    fullDate: "07 December, 2021",
    d: "07",
    m: "Dec",
    y: "2021",
    images: [dec_07_21_1, dec_07_21_2, dec_07_21_3, dec_07_21_4, dec_07_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Fit With Every Sip",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi organized an online session on “Hot Soup Activity” on 3rd December 2021 for the little learners under the supervision of their parents. The learners were apprised with all the material required for the soup- hot water, soup sachet, soup bowl, mat, apron etc. The learners were instructed by the facilitator about how to make the soup by mixing the soup sachet in hot water and stirring it for a minute. The soup was relished by the learners with their peers. The learners were made aware of the benefits of soup. The activity also helped in developing fine motor skills, eye-hand coordination,  following instructions and understanding safety rules.",
    fullDate: "04 December, 2021",
    d: "04",
    m: "Dec",
    y: "2021",
    images: [dec_04_21_1, dec_04_21_2, dec_04_21_3, dec_04_21_4, dec_04_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "National Pollution Prevention Day",
    text: "To sensitize students about the alarming increase in air pollution,the Primary wing of Chinmaya Vidyalaya New Delhi observed National Pollution Prevention Day on 2nd December 2021.This day holds special significance as it is observed in remembrance of all the people who lost their lives in the Bhopal Gas tragedy in 1984. Ways of preventing pollution and improving the environment were discussed with the learners to create awareness among them. They also participated in  activities such as plant a sapling,  paper bag making and creating a green corner with indoor plants.",
    fullDate: "03 December, 2021",
    d: "03",
    m: "Dec",
    y: "2021",
    images: [dec_03_21_1, dec_03_21_2, dec_03_21_3, dec_03_21_4, dec_03_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "FUN WITH NUMBERS",
    text: "To give hands-on experience to the little learners , the teachers of Pre- Primary organized an online session 'Fun with Numbers' on 29th November 2021. The activity aimed at identification, recognition and recapitulation of numbers and pre-number concepts to enlighten them with the different Math activites.",
    fullDate: "30 November, 2021",
    d: "30",
    m: "Nov",
    y: "2021",
    images: [nov_30_21_1, nov_30_21_2, nov_30_21_3, nov_30_21_4, nov_30_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Samvidhan Diwas",
    text: "Constitution Day also known as ‘Samvidhan Divas’, is celebrated in our country on 26 November every year to commemorate the adoption of the constitution of India. The students of Pre- Primary Department of Chinmaya Vidyalaya, New Delhi celebrated Indian Constitution Day on 26.11.2021 on virtual mode. The students were taught about Indian constitution, meaning and importance of rules and laws through various stories, poems and songs. Overall,it was an enriching session for all.",
    fullDate: "26 November, 2021",
    d: "26",
    m: "Nov",
    y: "2021",
    images: [nov_26_21_1, nov_26_21_2, nov_26_21_3, nov_26_21_4, nov_26_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Body Safety Rules",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi organised an online session the little ones to explain them about the safety rules and safe circle. It was conducted to make them aware about how to keep themselves safe. The students understood the concept with the help of  pictures and a PowerPoint Presentation that was shown to them. It was followed by a few examples of good touch and bad touch which gave them a better clarity on the concept. A few questions were asked from the students at the end of the session for recapitulation of the concept.",
    fullDate: "25 November, 2021",
    d: "25",
    m: "Nov",
    y: "2021",
    images: [nov_25_21_1, nov_25_21_2, nov_25_21_3, nov_25_21_4, nov_25_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Shape-O-Mania",
    text: "Learning shapes not only helps children identify and organize visual information, it also helps them learn skills in other curriculum areas. The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi organised an activity on shapes for the little learners wherein they were dressed in their favourite shapes and spoke a few lines on it. It helped in boosting their confidence and improved their oral skills.",
    fullDate: "24 November, 2021",
    d: "24",
    m: "Nov",
    y: "2021",
    images: [nov_24_21_1, nov_24_21_2, nov_24_21_3, nov_24_21_4, nov_24_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Guru Nanak Jayanti",
    text: "Guru Nanak Jayanti is celebrated to mark the birth of Guru Nanak Dev ji. It is celebrated with great zeal in India. The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi celebrated Gurpurab on 18.11.2021 on virtual mode. The students were taught about  the life history and teachings of Guru Nanak Dev ji. A serene and calm atmosphere was created by the soothing 'Shabad'.<br/>The session enriched the students with the teachings of Guru Nanak Dev ji.",
    fullDate: "19 November, 2021",
    d: "19",
    m: "Nov",
    y: "2021",
    images: [nov_19_21_1, nov_19_21_2, nov_19_21_3, nov_19_21_4, nov_19_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Virtual Tour - Traffic Park",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya,New Delhi organized a virtual tour of Traffic Park.It was a uniquely designed and interactive online activity wherein the important information regarding traffic rules and regulations was shared with the little learners.The objective was to encourage our young learners to learn basic road safety rules in a  'Fun Way'.They also enjoyed the craft on transportation .It was a fun- filled session enjoyed by learners with great enthusiasm.",
    fullDate: "18 November, 2021",
    d: "18",
    m: "Nov",
    y: "2021",
    images: [nov_18_21_1, nov_18_21_2, nov_18_21_3, nov_18_21_4, nov_18_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Virtual Tour - Traffic Park",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya,New Delhi organized a virtual tour of Traffic Park.It was a uniquely designed and interactive online activity wherein the important information regarding traffic rules and regulations was shared with the little learners.The objective was to encourage our young learners to learn basic road safety rules in a  'Fun Way'.They also enjoyed the craft on transportation .It was a fun- filled session enjoyed by learners with great enthusiasm.",
    fullDate: "18 November, 2021",
    d: "18",
    m: "Nov",
    y: "2021",
    images: [nov_18_21_1, nov_18_21_2, nov_18_21_3, nov_18_21_4, nov_18_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "RHYME-O-MANIA",
    text: "Rhymes enhance the awareness of a child and recitation helps to develop memorization skills. Guided by these thoughts,  Chinmaya Vidyalaya, New Delhi organised Rhyme-O-Mania activity on 15th November 2021 in which the learners had to recite the rhymes sung by their teachers. The little ones came up with different props related to the rhyme of their choice and recited them with great enthusiasm.",
    fullDate: "17 November, 2021",
    d: "17",
    m: "Nov",
    y: "2021",
    images: [nov_17_21_1, nov_17_21_2, nov_17_21_3, nov_17_21_4, nov_17_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Children's Day Celebrations",
    text: "The Mathematics Department of the Primary wing of Chinmaya Vidyalaya, New Delhi organised a virtual trip to Udaipur and Amritsar for the students of Classes I -V to celebrate the occasion of Children's Day. The students of I and II were dressed as tourists while visiting Udaipur and III- V learners were in  Punjabi attire while visiting Amritsar. They went sightseeing ,visited local markets for souvenirs and learnt about  the different festivals, cuisines and folk dances of their respective states. It was an exhilarating experience for the children ,giving them exposure to the cultural diversity of our country.",
    fullDate: "14 November, 2021",
    d: "14",
    m: "Nov",
    y: "2021",
    images: [nov_14_21_1, nov_14_21_2, nov_14_21_3, nov_14_21_4, nov_14_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Ready! Let set go!",
    text: "An online session was conducted by the Pre-Primary Department of Chinmaya Vidyalaya, New Delhi for the little learners. They participated enthusiastically in various games which helped them to balance and increase their concentration span. The children thoroughly enjoyed the activity.",
    fullDate: "13 November, 2021",
    d: "13",
    m: "Nov",
    y: "2021",
    images: [nov_13_21_1, nov_13_21_2, nov_13_21_3, nov_13_21_4, nov_13_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Inter House Art Competition",
    text: "“Art is an expression of internal imagination.“ – Vincent van Gogh<br/> To provide a platform for exploring the creative potential of the Class II learners, a virtual Inter House Pattern Art Competition was organized by Chinmaya Vidyalaya, New Delhi on 9th November 2021. The budding artists showcased their artistic skills by creating vibrant patterns to design the landscape scenery. The young learners participated in the competition with immense zeal and enthusiasm and impressed everyone with their artwork.",
    fullDate: "11 November, 2021",
    d: "11",
    m: "Nov",
    y: "2021",
    images: [nov_11_21_1, nov_11_21_2, nov_11_21_3, nov_11_21_4, nov_11_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Tessellation Patterns",
    text: "Teaching patterns to young learners requires the right tools and activities. This skill in Mathematics serves as an essential foundation in number sense. The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi organised an activity on Patterns for the little learners. While learning it, the learners saw examples of patterns, identified their characteristics and predicted what would come next. This helped them apply that knowledge to other Mathematical situations and also develop their number sense and observation skills.",
    fullDate: "09 November, 2021",
    d: "09",
    m: "Nov",
    y: "2021",
    images: [nov_09_21_1, nov_09_21_2, nov_09_21_3, nov_09_21_4, nov_09_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title:
      "Awareness that blooms even in a child as young as a Grade I student",
    text: "<div>Ayaana Pandey from grade I pours her understanding of SDGs through a video for the 1 SDG Fest 2021-Bringing SDGs to life. The little wise child surely wins our hearts.</div>",
    fullDate: "08 November, 2021",
    d: "08",
    m: "Nov",
    y: "2021",
    images: [
      "https://chinmayavvdelhi.ac.in/assets/home/awareness-that-blooms.png",
    ],
    isVideo: true,
    videoSrc: "https://www.youtube.com/embed/6iYCvcko7o8",
  },
  {
    title: "Sewa Session- Rishi Yagna",
    text: "In its endeavour to keep  the light of Sewa burning ,the Primary wing of Chinmaya Vidyalaya ,New Delhi<br>conducted a session to apprise the students about Rishi Yagna and the activity associated with it. This yagna is observed to show reverence to our Spiritual Masters and their teachings.Shlokas from Chapter 12 of Bhagawad Geeta were shared with the students, for them to learn and imbibe . The session was quite  enriching as it provided a deep insight about the Yagna.",
    fullDate: "08 November, 2021",
    d: "08",
    m: "Nov",
    y: "2021",
    images: [nov_08_21_1, nov_08_21_2, nov_08_21_3, nov_08_21_4, nov_08_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "HAPPY DIWALI",
    text: "Tamso Ma Jyotirgamaya<br/>Allow the Inner Light to remove the darkness of ignorance. Deepawali symbolizes positivity, hope and celebrations. Students from Pre-Primary to Senior wing celebrated Diwali with a special online assembly. The celebrations were marked by prayers and obeisance as the world moves towards light from the darkness.  A talk by Swami Chinamayanadaji was the highlight of the Senior School programme.  The  Primary students were encouraged to participate in charity towards the needy which is the true essence of the festival. The motto embraced by the students was : ‘Green Diwali, Clean Diwali’, to mark a pollution free festival. They showcased the spirit of Diwali through bhajans, poems and dance performances which added joy in their celebrations. The young learners of Pre -Primary also celebrated  by enthusiastically participating in the 'Joy of Giving' activities like decorating and gifting diyas.",
    fullDate: "04 November, 2021",
    d: "04",
    m: "Nov",
    y: "2021",
    images: [nov_04_21_1, nov_04_21_2, nov_04_21_3, nov_04_21_4, nov_04_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Puppet and Me",
    text: "The students of Class IV of the Primary wing of  Chinmaya Vidyalaya, New Delhi enjoyed an interesting English activity -'Puppet and me'. The children made a hand puppet of a fairy and presented a dialogue conversation, during which they asked the fairy to fulfil  some of their innermost wishes.The children came up with novel ways to present their act. The activity  was enjoyed by all the students as it provided them with an opportunity to express themselves freely.",
    fullDate: "01 November, 2021",
    d: "01",
    m: "Nov",
    y: "2021",
    images: [nov_01_21_1, nov_01_21_2, nov_01_21_3],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Celebrating Unity- Rashtriya Ekta Diwas",
    text: "As an endeavour to build the bond of oneness and unity, the Primary Wing of Chinmaya Vidyalaya, New Delhi celebrated the birth anniversary of Sardar Vallabhbhai Patel as Rashtriya Ekta Diwas (National Unity Day). A special online assembly was conducted on 29th October in the honour of the Iron Man of India. A biopic on the life of Sardar Vallabhbhai Patel was shared with the learners to highlight the major role of the great leader in the integration of India. They learnt about the diverse traditions and cultures of various states of India through an interactive session. This was followed by a patriotic song and an oath taking ceremony where the teachers and the students pledged to dedicate themselves to preserve the unity, integrity and internal security of the nation. The assembly ended with great enthusiasm with the students promising to strive hard and follow the vision of Sardar Vallabhbhai Patel.",
    fullDate: "31 October, 2021",
    d: "31",
    m: "Oct",
    y: "2021",
    images: [oct_31_21_1, oct_31_21_2, oct_31_21_3, oct_31_21_4, oct_31_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Speech is power: Speech is to persuade,to convert and to compel",
    text: "The Primary Department of Chinmaya Vidyalaya, New Delhi organised an Inter House Declamation Competition for the students of Class IV on the topic-'Technology makes people lonely'.The students participated keenly and expressed their views emphatically ,stressing on the merits and demerits of technology.The activity aimed at honing the oratory skills of the students and provided them with a platform to showcase their talent.",
    fullDate: "29 October, 2021",
    d: "29",
    m: "Oct",
    y: "2021",
    images: [oct_29_21_1, oct_29_21_2, oct_29_21_3],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Fun with colours",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi, organized an “Online Fun with Colours Activity”. The young learners enjoyed watching mixing of colours through an experiment on fun with colourful Cadbury Gems. They also enjoyed the rhyme played during the online session on colours. The learners were able to recognize primary and secondary colours through this activity which enriched their learning.",
    fullDate: "24 October, 2021",
    d: "24",
    m: "Oct",
    y: "2021",
    images: [oct_24_21_1, oct_24_21_2, oct_24_21_3, oct_24_21_4, oct_24_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Orange Day",
    text: "Colours are the smiles of nature, so is the colour Orange. Orange combines the energy of Red and the happiness of Yellow. On 22nd October, 2021 the little learners of Nursery celebrated Orange Day virtually with great zeal at Chinmaya Vidyalaya . They were amazed to create the hues and tints of this colour by blending Red and Yellow. They relished the orange snacks/ fruit / juice which they had and beautified their picture of Pumpkin by dabbing a piece of cotton in Orange colour.  It was a great learning activity which helped the children understand the concept of secondary colours. The kids enjoyed a lot and it was indeed a pleasure to witness little ones dressed up in orange bubbling with energy and fascination.",
    fullDate: "23 October, 2021",
    d: "23",
    m: "Oct",
    y: "2021",
    images: [oct_23_21_1, oct_23_21_2, oct_23_21_3, oct_23_21_4, oct_23_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Clean hands for all",
    text: "Washing hands with soap the right way plays a vital role in preventing a disease. In order to spread this awareness, the Primary Department of Chinmaya Vidyalaya, New Delhi, organized a special session on the occasion of ' Global Handwashing Day' wherein the theme of the day in 2021 and its significance were explained to the students. The students also learnt the message conveyed by the logo, benefits of handwashing and the seven appropriate steps of handwashing. Poster making on the theme of Global Handwashing Day 2021'Our future is at hand- Let's move forward together' reinforced their learning.",
    fullDate: "15 October, 2021",
    d: "15",
    m: "Oct",
    y: "2021",
    images: [oct_15_21_1, oct_15_21_2, oct_15_21_3, oct_15_21_4, oct_15_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Triumph of Good Over Evil",
    text: "Dussehra, a festival to celebrate the victory of good over evil, was celebrated by the students of Pre-Primary Department of Chinmaya Vidyalaya, New Delhi on 12th October 2021 with a lot of religious zeal and fervour. The program began with a prayer and a message on Dussehra. This was followed by “Ram Stuti” bhajan and a story on why we celebrate Dussehra. The celebrations not only enriched the students' learning but also filled the atmosphere with divine adoration.",
    fullDate: "14 October, 2021",
    d: "14",
    m: "Oct",
    y: "2021",
    images: [
      oct_14_21_1,
      oct_14_21_2,
      oct_14_21_3,
      oct_14_21_4,
      oct_14_21_5,
      oct_14_21_6,
      oct_14_21_7,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Inter House Fitness Challenge",
    text: "The Sports Department of Chinmaya Vidyalaya, New Delhi, organised an online Inter House Fitness Challenge, Feet Touch & Up, for the students of Class I on 6th October 2021 to promote physical activity among children. The competition focused on bending and stretching exercises to promote children’s flexibility. All participants competed with each other with a lot of zeal and fervor.",
    fullDate: "11 October, 2021",
    d: "11",
    m: "Oct",
    y: "2021",
    images: [oct_11_21_2_1, oct_11_21_2_2, oct_11_21_2_3, oct_11_21_2_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Be Active, Be Healthy, Be Happy",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi  celebrated Health Week to educate the children about healthy habits. During the week-long celebrations, the children inculcated the importance of healthy habits in their daily routine. Each day of the week was dedicated to a specific healthy habit. The Day 1 was dedicated to oral health and the learners were taught the right ways of brushing their teeth.The Day 2 focused on personal hygiene and children were encouraged to practice the habit of keeping themselves neat and clean. The Day 3 culminated the Health Week with an early morning yoga online session planned for all kids by our sports teachers.On Day 4, the learners were taught more about healthy eating through a story followed  by a  free-hand drawing on healthy and unhealthy food. The online session on non-fire cooking was conducted to give them the experience of healthy eating.",
    fullDate: "11 October, 2021",
    d: "11",
    m: "Oct",
    y: "2021",
    images: [oct_11_21_1, oct_11_21_2, oct_11_21_3, oct_11_21_4, oct_11_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "हिंदी है भारत की शान, आओ मिलकर बढ़ाए इसका स्वाभिमान।।",
    text: "हरि ओम,<br/>चिन्मय विद्यालय नई दिल्ली,<br/>प्राथमिक विभाग के विद्यार्थियों ने सितंबर मास में आयोजित की गई विविध  गतिविधियों में अत्यन्त उत्साह एवं उमंग के साथ भाग लेकर अपनी प्रतिभा का  उत्तम प्रदर्शन किया । जिससे उनके भाषा कौशल का सम्यक् विकास हुआ। ज्ञानवर्धक एवं रोचक गतिविधियां  छात्रों में हिंदी भाषा के प्रति अनुराग उत्पन्न करने में  सहायक सिद्ध हुईं। प्रमुख गतिविधियाँ कुछ इस प्रकार थी आओ कहानी बुनें, शब्द अंताक्षरी, कठपुतली क्या कहती है?, देखो मेरा देश और कठपुतली द्वारा कहानी वाचन।",
    fullDate: "08 October, 2021",
    d: "08",
    m: "Oct",
    y: "2021",
    images: [oct_08_21_1, oct_08_21_2, oct_08_21_3, oct_08_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Navratri Celebrations",
    text: "Students of Chinmaya Vidyalaya, classes   VI to  XII  welcomed the nine days of Navratri Celebrations with a special assembly. The colourful assembly heralded Ma Durga with an invocation. A poignant  message was conveyed to the students via video by Swami Supriyanandaji for classes VI to VIII and Swami Chinmayanandaji for classes IX to XII. Students came to know about the spiritual, mental and physical wellbeing, which is a part of the festivities.<br/>The children of the Junior department started the assembly with a prayer. They learned about the significance of the day and also enjoyed a Garba dance. A special Devi Prayer was followed by a concluding prayer.",
    fullDate: "07 October, 2021",
    d: "07",
    m: "Oct",
    y: "2021",
    images: [oct_07_21_1, oct_07_21_2, oct_07_21_3, oct_07_21_4, oct_07_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "The World of Animals",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi conducted various activities on ‘Animals’. A number of different integrated activities were planned for the learners. They made beautiful animals with finger painting, origami and clay. These activities not only helped the little ones to improve their eye-hand coordination but also enhanced their fine motor skills and aesthetic sense.",
    fullDate: "06 October, 2021",
    d: "06",
    m: "Oct",
    y: "2021",
    images: [
      oct_06_21_2_1,
      oct_06_21_2_2,
      oct_06_21_2_3,
      oct_06_21_2_4,
      oct_06_21_2_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Session on Yagna",
    text: "The Primary Department of Chinmaya Vidyalaya, New Delhi conducted a session on  ' Yagnas' as a part of Sewa activities. The teachers briefed the students about Deva Yagna. The session concluded with the Gayatri Mantra and its benefits.",
    fullDate: "06 October, 2021",
    d: "06",
    m: "Oct",
    y: "2021",
    images: [oct_06_21_1, oct_06_21_2, oct_06_21_3, oct_06_21_4, oct_06_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Gandhi Jayanti Celebration",
    text: "Chinmaya Vidyalaya, Vasant Vihar, celebrated Gandhi Jayanti on the virtual platform with a special assembly on his life, marking it as a day of tribute and veneration. Born on October 2, 1869. The assembly in the Senior Department commenced with an invocation followed by a virtual tour of Sabarmati Ashram. Classes VI - VIII also viewed an informative video on the Salt March held in the year 1931 and an inventive depiction of it in Gyarah Murti, located in the heart of the city. Students of classes IX - XII emersed themselves in  a documentary on the Swadeshi Movement and a talk on non - violence. Follow-up actives were also a part of the day’s programme, which included: poster -making, craftwork with Khadi material and creating PPT. The ever enthusiastic little learners of the  Primary department paid colourful homage to the great leader by putting up a puppet show: Mohan se Mahatma, highlighting some of the incidents from his life. A poem on cleanliness and a soulful bhajan: ‘Raghupati Raghav’ marked the day. An evocative music and dance recital was collated by the cultural department and was the highlight of the programme.",
    fullDate: "03 October, 2021",
    d: "03",
    m: "Oct",
    y: "2021",
    images: [oct_03_21_2_1, oct_03_21_2_2, oct_03_21_2_3, oct_03_21_2_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Gandhi Jayanti Celebration - Pre Primary",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi celebrated Gandhi Jayanti. The little ones were introduced to Gandhi ji and his principles through a story and it was followed by a small talk on Gandhi ji by our young learners. This was followed by a  song “Gandhi ji ke teen Bandar”. The value of cleanliness and the campaign of Swacch Bharat  was imparted in the young learners. The parents clicked the photographs of the children while they were cleaning their toys and bed. An action song depicting how cleanliness is important and how we can keep our surroundings clean was shared with them. The session concluded with an art and craft activity.",
    fullDate: "03 October, 2021",
    d: "03",
    m: "Oct",
    y: "2021",
    images: [oct_03_21_1, oct_03_21_2, oct_03_21_3, oct_03_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "It is the health which is the real wealth",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi conducted an online session for its young learners. The learners participated enthusiastically in various stretching exercises which helped them in increasing their flexibility and improving the stability of body posture. The children thoroughly enjoyed the activity.",
    fullDate: "01 October, 2021",
    d: "01",
    m: "Oct",
    y: "2021",
    images: [oct_01_21_1, oct_01_21_2, oct_01_21_3, oct_01_21_4, oct_01_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Animal Kingdom",
    text: "Fancy dress activity around animal theme is both fun and an opportunity to learn about animals. The Pre – Primary Department of Chinmaya Vidyalaya, New Delhi conducted a fancy dress activity on the theme ‘Animals’. The little ones came dressed up in colourful costumes and enacted their favorite animals. The activity helped the kids use their creativity and imagination. It also strengthened their communication skills.",
    fullDate: "24 September, 2021",
    d: "24",
    m: "Sep",
    y: "2021",
    images: [sep_24_21_1, sep_24_21_2, sep_24_21_3, sep_24_21_4, sep_24_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "The Best Of Virtual Tours",
    text: "The EVS Department of the Primary Wing of Chinmaya Vidyalaya, New Delhi took its students to various places with the help of virtual tours. The students dressed as a tourist and carried their munchies to make their experience feel real. The students enjoyed their trips to South India, Jaipur, Agra and Delhi and explored these places along with the explanation of the various facts associated with them.",
    fullDate: "18 September, 2021",
    d: "18",
    m: "Sep",
    y: "2021",
    images: [sep_18_21_1, sep_18_21_2, sep_18_21_3, sep_18_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "A New Way to Look at Arithmetic",
    text: "<div>Arthmetician Namit Singh presented a new way to look at Arithmatic by adding up the strengths and subtracting the negativities to stop Corona by making a wall and to make our country stand tall.</div>",
    fullDate: "18 October, 2021",
    d: "18",
    m: "Sep",
    y: "2021",
    images: [
      "https://chinmayavvdelhi.ac.in/assets/home/a-new-way-to-look-at-arithmetic.png",
    ],
    isVideo: true,
    videoSrc: "https://www.youtube.com/embed/hrHERDzvnaI",
  },

  {
    title: "The Best Of Virtual Tours",
    text: "The EVS Department of the Primary Wing of Chinmaya Vidyalaya, New Delhi took its students to various places with the help of virtual tours. The students dressed as a tourist and carried their munchies to make their experience feel real. The students enjoyed their trips to South India, Jaipur, Agra and Delhi and explored these places along with the explanation of the various facts associated with them.",
    fullDate: "18 September, 2021",
    d: "18",
    m: "Sep",
    y: "2021",
    images: [
      sep_17_21_2_1,
      sep_17_21_2_2,
      sep_17_21_2_3,
      sep_17_21_2_4,
      sep_17_21_2_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Fun Tour",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi, organised a virtual ‘Fun Tour’. It was a uniquely designed interactive online activity wherein interesting information about our different fun places of Delhi was shared with the young learners. They enjoyed the fun tour with great enthusiasm.",
    fullDate: "17 September, 2021",
    d: "17",
    m: "Sep",
    y: "2021",
    images: [
      sep_17_21_2_1,
      sep_17_21_2_2,
      sep_17_21_2_3,
      sep_17_21_2_4,
      sep_17_21_2_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Hop a little, jump a little!",
    text: "The Sports Department of Chinmaya Vidyalaya, New Delhi organised an online Inter House Competition, Jumping Jacks, for the students of Class II on 16th September 2021 to promote fitness amongst the little ones and to make them strong at mind and heart. The competition focused on making the participants achieve the maximum number of jumps within one minute. The enthusiastic participants competed with each other with a lot of zeal and fervor.",
    fullDate: "17 September, 2021",
    d: "17",
    m: "Sep",
    y: "2021",
    images: [sep_17_21_1, sep_17_21_2, sep_17_21_3, sep_17_21_4, sep_17_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "मन की भाषा. प्रेम की भाषा| हिंदी है जन- जन की भाषा||",
    text: "चिन्मय विद्‌यालय, नई दिल्ली के पूर्व प्राथमिक विभाग ने 'हिंदी दिवस' के उपलक्ष्य पर ऑनलाइन कविता पाठ का आयोजन किया। कक्षा नर्सरी और प्रेप के छात्र व छात्राओं ने उत्साहपूर्वक हिन्दी भाषा के प्रति प्रेम को कविताओं के माध्यम से उजागर किया। उनका यह प्रयास अतुलनीय एवं प्रशंसनीय रहा।",
    fullDate: "14 September, 2021",
    d: "14",
    m: "Sep",
    y: "2021",
    images: [sep_14_21_1, sep_14_21_2, sep_14_21_3, sep_14_21_4, sep_14_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Ganpati Bappa Moraya - Pre Primary",
    text: "To seek the blessings of Lord Ganesha, Ganesh Chaturthi was celebrated by the students of Pre- Primary Department of Chinmaya Vidyalaya, New Delhi in a unique way on Friday, 10.09.2021. The students made eco-friendly Ganesha at home. They were explained about the significance of the festival and the importance of eco-friendly ways of celebrating the festival. During the online session, the students performed dance, sang bhajans and shared their views on why Lord Ganesha is their favourite. The celebrations filled the atmosphere with divine adoration. Overall it was a joyful day for all.",
    fullDate: "10 September, 2021",
    d: "10",
    m: "Sep",
    y: "2021",
    images: [sep_10_21_1, sep_10_21_2, sep_10_21_3, sep_10_21_4, sep_10_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: '"You took my hand, opened my mind and touched my heart."',
    text: "The Primary Department of Chinmaya Vidyalaya, New Delhi celebrated Teachers' Day on virtual mode by organizing class parties wherein the students and teachers could interact and have fun over delicious munchies. The students made beautiful greeting cards and expressed their gratitude and love towards their teachers. The students also showcased their talents in different activities by means of a talent hunt. The teachers were honoured with titles and a few words of appreciation.",
    fullDate: "05 September, 2021",
    d: "05",
    m: "Sep",
    y: "2021",
    images: [
      sep_05_21_2_1,
      sep_05_21_2_2,
      sep_05_21_2_3,
      sep_05_21_2_4,
      sep_05_21_2_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Wishing All Teachers A Happy Teachers’ Day",
    text: "'Let us remember: one book, one pen, one child, and one teacher can change the world.' - Malala Yousafzai. Teachers’ Day is a special day to mark the birth anniversary of Dr S. Radhakrishnan. On this day students across the nation get together  to thank and remember  teachers who are instrumental in shaping their futures by giving them knowledge, wisdom and leading them  to contribute towards nation building. Students of class XII  showed  their appreciation for their teachers via a special online celebration. It was an  entertaining bouquet of music, dance and individual performances.",
    fullDate: "05 September, 2021",
    d: "05",
    m: "Sep",
    y: "2021",
    images: [sep_05_21_1, sep_05_21_2],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Holistic Development",
    text: "<div>The Vidyalaya offers ample opportunities for holistic development and Avni Pandey&#39;s performance proves the same.</div>",
    fullDate: "01 September, 2021",
    d: "01",
    m: "Sep",
    y: "2021",
    images: [
      "https://chinmayavvdelhi.ac.in/assets/home/Holistic-Development.png",
    ],
    isVideo: true,
    videoSrc: "https://www.youtube.com/embed/OgNIomWwhoM",
  },
  {
    title: "Janmashatami Celebrations",
    text: "A splendidly colorful program was organized by the Pre-Primary students of Chinmaya Vidyalaya, New Delhi. Dressed up in ethnic wear as Radha and Krishna, the students gathered for the virtual event. The students of Nursery class also made beautiful crowns, makhan matki and flutes which were very dear to our little Krishna. During the online session, the students presented bhajan, performed aarti and did an art & craft activity. The children were made aware of the mythological story on Krishna’s birth through a video.",
    fullDate: "30 August, 2021",
    d: "30",
    m: "Aug",
    y: "2021",
    images: [
      aug_30_21_2_1,
      aug_30_21_2_2,
      aug_30_21_2_3,
      aug_30_21_2_4,
      aug_30_21_2_5,
      aug_30_21_2_6,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Janmashtami - Lord Krishna is born.",
    text: "The birth anniversary of  Lord Krishna is a special day and the Primary and Senior students of  Chinmaya Vidyalaya, New Delhi celebrated it with full mirth and joy. Dressed up in traditional attire the  learners attended virtual assemblies which were an ensemble of beautiful bhajans , prayers and audio visuals on the life and teachings of Lord Krishna. The Primary students were apprised about the significance and  importance of the day. On the other hand the senior students gained deep insight and knowledge on why Shri Krishna took different avatars in different yugas? The celebration was not only entertaining but also enriched the students with different facts related to the festival.",
    fullDate: "30 August, 2021",
    d: "30",
    m: "Aug",
    y: "2021",
    images: [aug_30_21_1, aug_30_21_2, aug_30_21_3, aug_30_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Khel Utsav",
    text: "On the occasion of the National Sports Day, Chinmaya Vidyalaya, New Delhi organized ‘Khel Utsav’. The much-awaited virtual sports event of the Pre-Primary Department made everyone showcase immense enthusiasm as the little athletes stunned everyone with their amazing prowess and energy. They participated in Zumba Dance and Fun physical activities. The parents beamed in delight seeing their kids performing with perfection.",
    fullDate: "29 August, 2021",
    d: "29",
    m: "Aug",
    y: "2021",
    images: [aug_29_21],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/WJ3DyBodB4Y",
  },

  {
    title: "Dance is the hidden language of the soul",
    text: "With the prime purpose of enhancing the dancing skills of the young learners of classes I-II, a virtual Inter House Freestyle Solo Dance Competition was organized by Chinmaya Vidyalaya, New Delhi on 24th August 2021. The theme of the competition was ‘Save Trees, Save Nature’. The graceful dancers, clad in colourful costumes along with appropriate props, whirled and twirled to the music, depicting the plight and pleas of the trees. The promising dancers enthralled the spectators with their enthusiasm and energy. Such competitions are a great way to build coordination, mobility and emotional resilience in children.",
    fullDate: "25 August, 2021",
    d: "25",
    m: "Aug",
    y: "2021",
    images: [aug_25_21_1, aug_25_21_2, aug_25_21_3, aug_25_21_4, aug_25_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Dance is the hidden language of the soul",
    text: "With the prime purpose of enhancing the dancing skills of the young learners of classes I-II, a virtual Inter House Freestyle Solo Dance Competition was organized by Chinmaya Vidyalaya, New Delhi on 24th August 2021. The theme of the competition was ‘Save Trees, Save Nature’. The graceful dancers, clad in colourful costumes along with appropriate props, whirled and twirled to the music, depicting the plight and pleas of the trees. The promising dancers enthralled the spectators with their enthusiasm and energy. Such competitions are a great way to build coordination, mobility and emotional resilience in children.",
    fullDate: "25 August, 2021",
    d: "25",
    m: "Aug",
    y: "2021",
    images: [aug_25_21_1, aug_25_21_2, aug_25_21_3, aug_25_21_4, aug_25_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Curiosity is the very basic of Education",
    text: "A fun-filled and engaging activity ‘Magical Flower Experiment’ was organised on 23rd August, 2021 for the little learners of Chinmaya Vidyalaya, New Delhi.<br/><br/>A few interesting facts were shared with them. The virtual activity was enjoyed by all the learners.",
    fullDate: "24 August, 2021",
    d: "24",
    m: "Aug",
    y: "2021",
    images: [aug_24_21_2_1, aug_24_21_2_2, aug_24_21_2_3, aug_24_21_2_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Blue Day Celebration",
    text: "The Pre - Primary Department of Chinmaya Vidyalaya, New Delhi celebrated the Blue Day on 23rd August,2021. Excitement was visible everywhere. Clad in blue dresses, kids were made to recognize blue colour through a series of activities  to make learning fun for them. Early identification of colours helped to create the cognitive link between visual clues and words which is an important part of a child’s development.",
    fullDate: "24 August, 2021",
    d: "24",
    m: "Aug",
    y: "2021",
    images: [aug_24_21_1, aug_24_21_2, aug_24_21_3, aug_24_21_4, aug_24_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "A Bond of Love, A Bond of Togetherness",
    text: "Rakshabandhan, a much awaited Indian traditional festival was celebrated by the Pre-Primary students of Chinmaya Vidyalaya, New Delhi with full enthusiasm.<br/><br/>The students virtually gathered to celebrate the special day wearing traditional clothes. The students were told about the sacred bond between a brother and a sister through a story, a poem and a traditional song.<br/><br/>The session culminated with an activity of chocolate ladoo making.",
    fullDate: "22 August, 2021",
    d: "22",
    m: "Aug",
    y: "2021",
    images: [aug_22_21_1, aug_22_21_2, aug_22_21_3, aug_22_21_4, aug_22_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "A Bond of Love, A Bond of Togetherness",
    text: "Rakshabandhan, a much awaited Indian traditional festival was celebrated by the Pre-Primary students of Chinmaya Vidyalaya, New Delhi with full enthusiasm.<br/><br/>The students virtually gathered to celebrate the special day wearing traditional clothes. The students were told about the sacred bond between a brother and a sister through a story, a poem and a traditional song.<br/><br/>The session culminated with an activity of chocolate ladoo making.",
    fullDate: "22 August, 2021",
    d: "22",
    m: "Aug",
    y: "2021",
    images: [aug_22_21_1, aug_22_21_2, aug_22_21_3, aug_22_21_4, aug_22_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Kahaniyon Ki Potli",
    text: "The young learners of the Pre-Primary Department enthusiastically participated in the 'Online Kahaniyon Ki Potli Activity' wherein they got an opportunity to exhibit their talent and confidence. The young learners narrated their favourite stories with beautiful props.",
    fullDate: "19 August, 2021",
    d: "19",
    m: "Aug",
    y: "2021",
    images: [aug_19_21_1, aug_19_21_2, aug_19_21_3, aug_19_21_4, aug_19_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "I am the Champion",
    text: "<div>With an objective to enable the students to learn about various concepts from different subjects like Mathematics, English, Social Science and Value Education in a fun filled way, the little champion Jatin Chaudhary created his own game - I am the Champion.</div>",
    fullDate: "16 August, 2021",
    d: "16",
    m: "Aug",
    y: "2021",
    images: ["https://chinmayavvdelhi.ac.in/assets/home/i-am-the-champion.png"],
    isVideo: true,
    videoSrc: "https://www.youtube.com/embed/BkbAtvbkMC8",
  },

  {
    title: "Special Assembly on Independence Day",
    text: "Marking the freedom and glory of  the Nation,the Pre-Primary Department of Chinmaya Vidyalaya,New Delhi organised a special assembly on Independence Day with patriotic fervour and zeal. The students participated in a dance presentation with immense enthusiasm. It was followed by a patriotic song and an informative talk on Independence Day. The children learnt about the significance of this historic day.They were motivated to be an ideal citizen of India.<br/><br/>The virtual assembly culminated with the National Anthem.",
    fullDate: "15 August, 2021",
    d: "15",
    m: "Aug",
    y: "2021",
    images: [aug_15_21],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/rYUd0pRE8Uc",
  },

  {
    title: "Monsoon Magic",
    text: "The Pre – Primary Department of Chinmaya Vidyalaya, New Delhi organized an activity on monsoon season. The students were explained about the beauty of the Monsoon season. The Nursery and Prep children made a collage on rainy season.The children expressed their excitement by showing their colorful umbrellas to their friends and teachers.",
    fullDate: "07 August, 2021",
    d: "07",
    m: "Aug",
    y: "2021",
    images: [aug_07_21_1, aug_07_21_2, aug_07_21_3, aug_07_21_4, aug_07_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Health Experts",
    text: "<div>Surabhi Sameesh from Nur-A shares the secret of her Organic Mantra and finds her way not only to our hearts but also to our kitchens.</div>",
    fullDate: "06 August, 2021",
    d: "06",
    m: "Aug",
    y: "2021",
    images: ["https://chinmayavvdelhi.ac.in/assets/home/health-experts.png"],
    isVideo: true,
    videoSrc: "https://www.youtube.com/embed/vVHDrK3h18w",
  },

  {
    title: "Exploring air and space",
    text: "The EVS Department of Chinmaya Vidyalaya, New Delhi organized an array of S.T.E.M. activities for Classes III-V to allow students to explore concepts beyond their textbooks. Interesting activities on the theme -'My Air ,My Space' were conducted to provide deeper understanding and knowledge about the concept.The students enjoyed making parachutes, fruit rockets, and  phases of the moon on Oreo biscuits.Such hands-on experiential activities not only create interest in the subject but also provide moments of fun and joy.",
    fullDate: "04 August, 2021",
    d: "04",
    m: "Aug",
    y: "2021",
    images: [aug_04_21_1, aug_04_21_2, aug_04_21_3, aug_04_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "SADHANA DIWAS (Gurudev Swami Chinmayanandaji’s Maha Samadhi Day)",
    text: "Chinamaya Vidyalaya, New Delhi observed the pious occasion of Gurudev’s Maha Samadhi as a day of remembrance and prayer. Students from Pre- primary to class XII marked  the occasion with various activities, culminating in an online assembly in the morning. Student’s learned about Gurudev’s contribution towards worldly upliftment, universal knowledge and individual wisdom. Children honoured the day through various endeavours like chanting his 108 names and Arti; writing essay on his vibrant personality; conducting an invigorating class discussion on his philosophy and understanding. The young learner’s participated in listening to stories related to Gurdev’s life, meditating and stringing together a garland to instill faith.In a quiet but determined way the students of the Vidyalaya remembered his message - a legacy to be carried within.",
    fullDate: "03 August, 2021",
    d: "03",
    m: "Aug",
    y: "2021",
    images: [aug_03_21_1, aug_03_21_2, aug_03_21_3, aug_03_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Our Success Stories: 2020-21",
    text: "Here is the list of  the meritorious students from our Vidyalaya who have passed the CBSE class 12 exams with flying colours.<br>Congratulations and we wish you all tremendous success in the coming years. Through you, the lamp of our school will remain forever burning, illuminating the road for others.",
    fullDate: "31 July, 2021",
    d: "31",
    m: "July",
    y: "2021",
    images: [july_31_21],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "When Disaster Strikes...",
    text: "We cannot stop natural disasters but we can arm ourselves with knowledge. With this in mind, a myriad of fun-filled and engaging activities were organised from 26th to 30th July, 2021 on the virtual platform for the learners of classes I and II of Chinmaya Vidyalaya, New Delhi.<br/><br/> Fun projects, activities and games were undertaken to make learning about earthquakes, floods and landslides engaging for the students. They actively participated in the online evacuation drill and prepared a First-Aid box for their family.<br/><br/>The online sessions aimed at making the young learners aware of the ways of preparing themselves for a disaster and how to respond once the immediate danger had passed.",
    fullDate: "31 July, 2021",
    d: "31",
    m: "July",
    y: "2021",
    images: [
      july_31_21_2_1,
      july_31_21_2_2,
      july_31_21_2_3,
      july_31_21_2_4,
      july_31_21_2_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Colour of Nature's Heart",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi organized Green Day with great zeal and enthusiasm for the little learners of the Vidyalaya on 30th July ’21. Bubbling with enthusiasm in their vibrant green costumes, the little ones created a pleasant and refreshing aura.",
    fullDate: "31 July, 2021",
    d: "31",
    m: "July",
    y: "2021",
    images: [
      july_31_21_1,
      july_31_21_2,
      july_31_21_3,
      july_31_21_4,
      july_31_21_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Save tigers, Save our national animal",
    text: "Global Tiger Day also known as International Tiger Day is an annual celebration to raise awareness for tiger conservation. The Pre - Primary students of Chinmaya Vidyalaya, New Delhi celebrated the 'World Tiger Day' on 29th July, 2021. The students were shown a video to explain tiger conservation.It was followed by a few interesting facts about tigers and the reasons of their decline.The students and teachers wore different shades of orange or yellow for the session.",
    fullDate: "29 July, 2021",
    d: "29",
    m: "July",
    y: "2021",
    images: [
      july_29_21_1,
      july_29_21_2,
      july_29_21_3,
      july_29_21_4,
      july_29_21_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Chinmaya Vidyalaya - Virtual Tour",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi, organised a virtual tour of ‘Chinmaya Vidyalaya’. It was a uniquely designed interactive online activity wherein interesting information about our Vidyalaya was shared with the young learners. They enjoyed the school tour with great enthusiasm.",
    fullDate: "28 July, 2021",
    d: "28",
    m: "July",
    y: "2021",
    images: [july_28_21_1, july_28_21_2, july_28_21_3, july_28_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Guru: The path towards enlightenment",
    text: "On the auspicious occasion of Guru Purnima, Chinmaya Vidyalaya wishes everyone a day of grace and new possibilities. ‘Guru’ is the supreme, the benevolent and all encompassing. When one finds the true ‘Guru’ then life’s purpose becomes articulated . Students  of the Primary, Middle and Senior wing joined together to pay homage to the Guru . It was a  day marked with prayer, shlokas and colourful activities. Students stated their love and respect for their Gurus through songs and poetry . The true essence of the day was highlighted with help of talks, discussions and it was indeed a truly enriching experience.",
    fullDate: "24 July, 2021",
    d: "24",
    m: "July",
    y: "2021",
    images: [july_24_21_3_1, july_24_21_3_2, july_24_21_3_3, july_24_21_3_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Show and Tell",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi, organised an online Show and Tell activity on the theme ‘Food’. The young learners chose their favourite food items and spoke a few lines about them. They used beautiful props while describing their food items.",
    fullDate: "24 July, 2021",
    d: "24",
    m: "July",
    y: "2021",
    images: [july_24_21_2_1, july_24_21_2_2, july_24_21_2_3, july_24_21_2_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Physical fitness is the most important key to a healthy body.",
    text: "An online session on fitness was conducted for the students of  Pre - Primary Department of Chinmaya Vidyalaya, New Delhi. The young learners performed various balancing exercises and played many balancing games. The children learnt to control and stabilize their body positions. The activity was enjoyed with great enthusiasm by all the children.",
    fullDate: "24 July, 2021",
    d: "24",
    m: "July",
    y: "2021",
    images: [july_24_21_1, july_24_21_2, july_24_21_3, july_24_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Celebrate the taste of summer with the king of fruits.",
    text: "The Primary Department of Chinmaya Vidyalaya, New Delhi celebrated the Mango festival on virtual mode. Dressed in vibrant yellow and green attires with beautiful head gears , the students enjoyed the activities based on mango recipes. The students learnt about the significance of Mango festival, the nutritive value of mangoes and the various ways of consuming it. The activity on the various names of mangoes and the stories of their origin enriched the students with interesting details about them.",
    fullDate: "22 July, 2021",
    d: "22",
    m: "July",
    y: "2021",
    images: [july_22_21_2_1, july_22_21_2_2, july_22_21_2_3, july_22_21_2_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Your body belongs to you",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya,New Delhi organised  an online session on ‘Good touch and bad touch’. It was conducted to make the little ones  aware about their safety.The students understood the concept with the help of a video that was shown to them.  It was followed by a few examples of good touch and bad touch which gave them a better clarity on the concept. A home assignment was also given in which the children were asked to draw a safe circle and paste the picture of their most trusted family member.",
    fullDate: "22 July, 2021",
    d: "22",
    m: "July",
    y: "2021",
    images: [july_22_21_1, july_22_21_2, july_22_21_3, july_22_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Road to Enhance My Senses",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi, planned a SENSORIAL WEEK from 12th July - 15th July to celebrate the gift of senses.",
    fullDate: "17 July, 2021",
    d: "17",
    m: "July",
    y: "2021",
    images: [july_17_21_1, july_17_21_2, july_17_21_3, july_17_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Bhajan – a path to devotion and bliss.",
    text: "Devotional singing induces faith and helps us to imbibe spiritual values. The students of Classes III-V of Chinmaya Vidyalaya , New Delhi showcased their talent in the Inter House Solo “Bhajan Competition” wherein they offered their love to the Almighty by singing  soulful bhajans of Lord Rama, Krishna and Shiva.The melodious chants reverberated in the air producing an aura of peace and tranquility. Participants of each house tried to outdo each other by their spectacular performances. The event helped the students to connect to Indian values and culture.",
    fullDate: "16 July, 2021",
    d: "16",
    m: "July",
    y: "2021",
    images: [
      july_16_21_2_1,
      july_16_21_2_2,
      july_16_21_2_3,
      july_16_21_2_4,
      july_16_21_2_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Conserve Water, Conserve Life",
    text: "The learners of the Pre-Primary Department of Chinmaya Vidyalaya, New Delhi, were apprised  about the importance and uses of water. Through a story, the kids were shown the various uses of water and the results of wasting it. The little ones learnt the significance of water and the different ways to save it. This was followed by a craft activity.The children also promised to spread the message of SAVE WATER amongst their family members.",
    fullDate: "16 July, 2021",
    d: "16",
    m: "July",
    y: "2021",
    images: [
      july_16_21_1,
      july_16_21_2,
      july_16_21_3,
      july_16_21_4,
      july_16_21_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Fitness helps us think better, feel better and move better.",
    text: "The Primary Department of Chinmaya Vidyalaya, New Delhi, organized an Inter House Fitness Planks Competition for class V on virtual mode. The fitness enthusiasts displayed a perfect balance of body and stamina during the competition. The participants were adjudged on the basis of perfection in body posture and the time taken. The students learnt to strengthen their spine and the proper posture helped them align their bones.",
    fullDate: "09 July, 2021",
    d: "09",
    m: "July",
    y: "2021",
    images: [july_09_21_1, july_09_21_2, july_09_21_3, july_09_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Tablet Distribution Programme For EWS Students",
    text: "To ensure uninterrupted online education for the EWS  students especially during the challenging times of Covid Pandemic, Delhi Chinmaya Sewa Trust, in association with WIKA, distributed tablets to the EWS  students of  classes VI to XI of Chinmaya Vidyalaya.",
    fullDate: "6-8 July, 2021",
    d: "6-8",
    m: "July",
    y: "2021",
    images: [
      ews4,
      ews1,
      ews2,
      ews3,
      ews5,
      ews6,
      ews7,
      ews8,
      ews9,
      ews10,
      ews11,
      ews12,
      ews13,
      ews14,
      ews15,
      ews16,
      ews17,
    ],
    isVideo: false,
    videoSrc: "",
  },

  {
    title: "Red Day Celebration",
    text: "The Pre Primary Department of Chinmaya Vidyalaya, New Delhi celebrated the 'Red Day' with young learners of Nursery. It was a wonderful session which helped the children to sort and classify objects based on colours. The faces of the young learners beamed in delight. The activity helped them sort and classify objects based on colours and reinforced their cognitive skills.",
    fullDate: "02 July, 2021",
    d: "02",
    m: "July",
    y: "2021",
    images: [july_02_21_2_1, july_02_21_2_2, july_02_21_2_3, july_02_21_2_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Let your creative talent sparkle at its zenith.",
    text: "Creativity flourishes in an atmosphere of serenity and liveliness. Classes IV &V of Chinmaya Vidyalaya, New Delhi participated in an Inter House Animal  Mask Making Competition which provided them with a platform to give vent to their  imagination.Masking offers a unique opportunity to have lot of fun and the students dived passionately into the spirit  of healthy competition, creating beautiful animal masks. The ideas and amazing creations of the students were a vivid reflection of their immense talent. The event was an enriching experience that was enjoyed by all.",
    fullDate: "02 July, 2021",
    d: "02",
    m: "July",
    y: "2021",
    images: [july_02_21_1, july_02_21_2, july_02_21_3, july_02_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "National Doctors' Day",
    text: "The Primary Department of Chinmaya Vidyalaya, New Delhi, celebrated 'National Doctors' day' to honour the excellent and selfless services of the front line warriors of the society. The activities enriched the students with information on tools of the doctors and abbreviations used by them in prescriptions. The students also expressed their gratitude to their family doctors by writing a thank you note inside a greeting card. It was an enriching experience for all.",
    fullDate: "01 July, 2021",
    d: "01",
    m: "July",
    y: "2021",
    images: [july_01_21_1, july_01_21_2, july_01_21_3, july_01_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Innovation is where imagination meets ambition.",
    text: "Chinmaya Vidyalaya, New Delhi, organized an Inter House Computer Competition ' Pecha Kucha 2021' for the students of class V of the Primary Department on virtual mode. Guided by the teachers of the computer department and the House Wardens, the participants designed innovative power point presentations on the theme  'Innovation and Life' using 'Pecha Kucha' platform, a Japanese way of storytelling with visuals. <br/><br/>The event introduced the participants to an innovative and extraordinary way of making presentations and enhanced their creative thinking.",
    fullDate: "29 June, 2021",
    d: "29",
    m: "June",
    y: "2021",
    images: [june_29_21_1, june_29_21_2, june_29_21_3, june_29_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Music - The universal language of mankind",
    text: "The  Primary Section and the Senior Section of Chinmaya Vidyalaya, New Delhi, organised virtual sessions to celebrate the World Music Day on 21st June 2021. The vital role of music in the holistic development of the learners was highlighted during the sessions. The promising musicians from the Primary section made the day more musical and melodious by singing along with their music teacher whereas the senior students took everyone to the world of music by sharing information on famous musicians. The special day provided a great opportunity for our budding artists to showcase their talent in the field of music.",
    fullDate: "21 June, 2021",
    d: "21",
    m: "June",
    y: "2021",
    images: [june_21_21_2_1, june_21_21_2_2, june_21_21_2_3],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "International Yoga Day",
    text: "Chinmaya Vidyalaya, New Delhi celebrated International Yoga Day by holding morning yoga sessions from 8th to 21st June 2021. It was attended by the students , parents and staff members. The attendees were able to benefit from the various asanas that they performed during each session. The principles of Yoga were reinforced to prove that a healthy body is the key to a healthy overall sustenance.",
    fullDate: "21 June, 2021",
    d: "21",
    m: "June",
    y: "2021",
    images: [june_21_21_1, june_21_21_2, june_21_21_3],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "A Day with My Daddy",
    text: "The Pre-Primary students of Chinmaya Vidyalaya, in order to make their doting fathers proud and pay filial respect and gratitude, celebrated Father’s Day in a spectacular way. Students made cards as a gift for their beloved fathers and played various games with them strengthening their bond.",
    fullDate: "19 June, 2021",
    d: "19",
    m: "June",
    y: "2021",
    images: [june_19_21_1, june_19_21_2, june_19_21_3, june_19_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "A Slice Of Summer",
    text: "An interesting online session was conducted by the Pre-Primary department,Chinmaya Vidyalaya, New Delhi  for the students. Children were asked to dress in summer clothes while teachers presented various facts about the summer season. Children were also asked to care for the environment and plant more trees to reduce the heat. Children with the help of parents created a delicious Pudding Cake made of sponge cake, mangoes and custard.",
    fullDate: "16 June, 2021",
    d: "16",
    m: "June",
    y: "2021",
    images: [
      june_16_21_1,
      june_16_21_2,
      june_16_21_3,
      june_16_21_4,
      june_16_21_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Gratitude Bestows Reverence",
    text: "Chinmaya Vidyalaya, New Delhi reopened virtually after the summer break on 10th June 2021, with a 'Welcome Assembly' for Classes VI to XII on the theme - Gratitude Bestows Reverence. <br/>The past few months have left a stressful impact on our lives and we feel humbled by the fact that we are able to meet and greet each other. ",
    fullDate: "10 June, 2021",
    d: "10",
    m: "June",
    y: "2021",
    images: [june_10_21_3_1, june_10_21_3_2, june_10_21_3_3, june_10_21_3_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Keep the smile on....",
    text: "The sounds of laughter and joy reverberated through the virtual classrooms of the Primary Department of Chinmaya Vidyalaya, New Delhi, when the students were welcomed back to school after the summer break with an assembly on the theme of happiness. Students shared some interesting insights about what makes them happy. Back in the company of their friends they sang songs, chanted, meditated, enjoyed laughter exercises and short stories on happiness.",
    fullDate: "10 June, 2021",
    d: "10",
    m: "June",
    y: "2021",
    images: [june_10_21_2_1, june_10_21_2_2, june_10_21_2_3, june_10_21_2_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Kiddos Funshala",
    text: "Pre-Primary teachers of Chinmaya Vidyalaya welcomed their young learners after summer vacations with various fun filled activities. Students enjoyed dancing on the tune of the song 'If you are happy and you know it...'. The students shared a happy face emoji to highlight the joy of joining back to the virtual platform. They enjoyed every moment of the assembly that brought happiness and cheer for all.",
    fullDate: "10 June, 2021",
    d: "10",
    m: "June",
    y: "2021",
    images: [june_10_21_1, june_10_21_2, june_10_21_3, june_10_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "World Environment Day",
    text: "On World Environment Day, the young learners of Pre-Primary Department, Chinmaya Vidyalaya, New Delhi promise to fulfil this responsibility by laying emphasis on Reduce, Re-use and Recycle.",
    fullDate: "05 June, 2021",
    d: "05",
    m: "June",
    y: "2021",
    images: [june_05_21],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/D7wCE0lrfDE",
  },

  {
    title: "“The Earth is what we all have in common” - Wendell Berry",
    text: "The Primary Department of Chinmaya Vidyalaya, New Delhi, celebrated the World Environment Day by engaging the learners in a few activities that aimed at spreading awareness for the safety of green planet. The learners enjoyed making the clay model of the Earth, an eco- friendly paper bag, handmade paper using old newspaper and activities based on energy conservation. The activity 'Water Inspectors' was conducted on water conservation. <br/>The activities helped the learners gain information on the ways to conserve the environment.",
    fullDate: "05 June, 2021",
    d: "05",
    m: "June",
    y: "2021",
    images: [june_05_21_1, june_05_21_2, june_05_21_3, june_05_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Gurudev’s 105 Jayanti - Om Bharatagauravaya Namah!",
    text: "On the occasion of Gurudev's Jayanti, the students of Chinmaya Vidyalaya of classes Nursery-XII, delved deeper to understand the true essence of Sewa and cherished Gurudev's  teachings. Our students displayed exemplary mental strength by coming up with ideas, words and creativity that spread positivity and love.<br/> <br/>Chinmaya Vidyalaya, New Delhi also organised a 'Session' on 8th May 2021 based on the theme ‘Om Bharatagauravaya Namah’ to seek Gurudev's blessings and pay humble prostrations at His lotus feet.",
    fullDate: "08 May, 2021",
    d: "08",
    m: "May",
    y: "2021",
    images: [may_08_21_1, may_08_21_2, may_08_21_4, may_08_21_3],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Humble Prostrations At The Feet Of Lord Hanuman",
    text: "Chinmaya Vidyalaya, New Delhi, virtually organised a special session on Hanuman ji on 27th April 2021 to commemorate the auspicious birth of Sri Hanuman, an ardent devotee and eternal servitor of Lord Sri Ramachandra.<br/>The Pre-primary and  Primary school students got an insight into the life and qualities of Lord Hanuman through an informative video presentation. They also chanted prayers. The Middle and Senior school students connected to the occasion by singing bhajans, chanting Hanuman Chalisa, and participating in an interactive discussion on the importance of ‘Faith’ in one’s life.<br/>The auspicious day infused all with the courage to face the difficult times with faith, hope, and optimism.",
    fullDate: "27 April, 2021",
    d: "27",
    m: "April",
    y: "2021",
    images: [april_27_21_1, april_27_21_2, april_27_21_3, april_27_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Let’s restore our planet Earth and value its worth",
    text: "Chinmaya Vidyalaya, New Delhi, celebrated Earth day by conducting a special online assembly for Classes I to XII. <br/>The assembly aimed at making the children aware of the ways of protecting our planet and emphasizing the significance of Reduce,Reuse and Recycle in daily lives. An informative video on 22 inventions all over the world to conserve the planet enriched everyone.<br/><br/>A myriad of activities were undertaken during the assembly to celebrate the day. The Primary students expressed their views on the ways of protecting the planet and made a pencil stand using a can or a bottle. The Middle and Senior school students created awareness by making e-cards, e-posters, planting a tree and analyzing the causes of wetland degradation.The activities helped in spreading awareness on the ways to restore our planet Earth.",
    fullDate: "22 April, 2021",
    d: "22",
    m: "April",
    y: "2021",
    images: [
      april_22_21_1,
      april_22_21_2,
      april_22_21_3,
      april_22_21_4,
      april_22_21_5,
    ],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Together we can Act and Save our Mother Earth",
    text: "In a bid to raise awareness on protecting our Mother Earth, the Pre-Primary children of Chinmaya Vidyalaya, New Delhi took the initiative of spreading the message of 'Together we can Act and Save our Mother Earth'",
    fullDate: "22 April, 2021",
    d: "22",
    m: "April",
    y: "2021",
    images: [april_22_21],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/vBmxxAqGAd4",
  },

  {
    title: "Ram Navami Celebrations",
    text: "To mark the birth of Lord Rama, Chinmaya Vidyalaya, New Delhi, celebrated Ram Navami by conducting special online assembly for classes Nursery to XII. <br/><br/>The assembly aimed at highlighting the relevance of celebrating this auspicious festival. Important virtues learnt from Lord Rama's life, such as obedience, respect and many more were discussed and also the need to imbibe these values, was stressed upon. <br/><br/>A plethora of activities were undertaken during the assembly to celebrate the occasion. The atmosphere was filled with positivity and harmony as the Pre-Primary and Primary students chanted ‘Ram Naam’ 21 times and sang a devotional bhajan on Shri Ram. Students of Middle section expressed their devotion through their drawings of Lord Ram. A discussion on the importance of values in life and respecting parents was undertaken by the Senior students. The multiple activities partaken helped in instilling spiritual and moral values amongst the learners.",
    fullDate: "21 April, 2021",
    d: "21",
    m: "April",
    y: "2021",
    images: [april_21_21_1, april_21_21_2, april_21_21_3, april_21_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Online Orientation of SheCodes Innovation Challenge",
    text: "An Orientation of SheCodes Innovation Challenge was conducted online for the Girl students of Chinmaya Vidyalaya, New Delhi from Classes VI to X on 16th April 2021.<br/><br/>Mr. Neeraj Sharma, Lead Consultant in Learning Links Foundation in association with AIM, NITI Aayog conducted and introduced the session.<br/><br/>SheCodes Innovation Challenge, is an initiative launched by Atal Innovation Mission(AIM), NITI Aayog, Government of India in collaboration with Dell Technologies and Learning Links Foundation to help the girl students to make effective use of the disruptive technology tools available in the ATLs & support them to be at par with their male counterparts.",
    fullDate: "17 April, 2021",
    d: "17",
    m: "April",
    y: "2021",
    images: [april_17_21_1, april_17_21_2, april_17_21_3],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Celebrating Hope, Harmony And Harvest",
    text: "The Primary Department of Chinmaya Vidyalaya, New Delhi, celebrated the harvest festival of Baisakhi on virtual mode. <br/><br/>A special assembly was held and it aimed at apprising the learners of the significance of the festival. An animated video on the history of Baisakhi and discussion on various harvest festivals of India enlivened the session. The learners enjoyed tapping their feet on two punjabi songs. The vibrant punjabi attires of the learners made the dance session a visual treat for the eyes.",
    fullDate: "13 April, 2021",
    d: "13",
    m: "April",
    y: "2021",
    images: [april_13_21_1, april_13_21_2, april_13_21_3, april_13_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Health for All",
    text: "World Health Day was celebrated by the young learners of class Prep on 7th April, 2021. An interactive session was organized for the little ones, wherein the Pre-Primary teachers enacted as grannies and shared the benefits of good health. They emphasized on good eating habits, made healthy fruit salad and also shared the importance of physical exercises. The activity was indeed informative as it touched upon significant facts about good health and well being.",
    fullDate: "08 April, 2021",
    d: "08",
    m: "April",
    y: "2021",
    images: [april_08_21_1, april_08_21_2, april_08_21_3, april_08_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Fun Filled Week",
    text: "To make the last week of the session a memorable one for the young learners, the Pre-primary Department of Chinmaya Vidyalaya, New Delhi organized a 'Fun Filled Week'. <br/><br/>During the week long celebrations, the learners enjoyed various activities like Virtual Tour of Disneyland, Puppet Show, Cooking without Flame and many exciting games along with a class party. They enjoyed these activities with zeal and enthusiasm, sharing their happiness of completing another milestone in their lives.",
    fullDate: "16 March, 2021",
    d: "16",
    m: "Mar",
    y: "2021",
    images: [mar_16_21_1, mar_16_21_2, mar_16_21_3, mar_16_21_4, mar_16_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Buzzing Bees",
    text: "The young learners of the Pre-Primary Department enthusiastically participated in the 'Online Rhyme Recitation Activity' wherein they got an opportunity to exhibit their talent and confidence. They came up with different rhymes and beautiful props on the theme 'Nature'.",
    fullDate: "08 March, 2021",
    d: "08",
    m: "Mar",
    y: "2021",
    images: [mar_08_21_2_1, mar_08_21_2_2, mar_08_21_2_3, mar_08_21_2_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Virtual ATL Community Day",
    text: "Chinmaya Vidyalaya hosted a webinar on ‘Coding and Artificial Intelligence’ on 5th March 2021 which was followed by the announcement of the ATL TinkerFest Results.<br/><br/> Mr Aman Upadhyay, Sr. Innovation Engineer from StemRobo was the educator for the day and several concepts of Artificial Intelligence and Coding were taken up.<br/><br/> Around fifty participants from different Schools joined the webinar and benefitted from the session.",
    fullDate: "08 March, 2021",
    d: "08",
    m: "Mar",
    y: "2021",
    images: [mar_08_21_1, mar_08_21_2, mar_08_21_3],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Indoor Sports Fun Activity Day",
    text: "The Pre -Primary Department of Chinmaya Vidyalaya, New Delhi organized an 'INDOOR SPORTS FUN ACTIVITY DAY' for the energetic young learners. They participated enthusiastically in various events which helped them in building their stamina and agility. It was followed by an activity wherein the parents participated along with their children. It not only made them feel more connected but also strengthened the bond between them.",
    fullDate: "06 March, 2021",
    d: "06",
    m: "Mar",
    y: "2021",
    images: [mar_06_21],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/l2iVwS11Hng",
  },
  {
    title: "The Wonder of Play Dough",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi organized a clay moulding activity for the young learners of Nursery class. They made beautiful aquatic animals using clay. The activity not only helped the little ones to improve their eye-hand coordination but also enhanced their fine motor skills and aesthetic sense.",
    fullDate: "25 February, 2021",
    d: "25",
    m: "Feb",
    y: "2021",
    images: [feb_25_21_1, feb_25_21_2, feb_25_21_3, feb_25_21_4, feb_25_21_5],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Budding Artists",
    text: "Drawing is a form of visual art which helps to express feelings and exhibit one's artistic talent.<br/><br/>The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi organized an online drawing and colouring activity for the young learners, wherein they drew and coloured beautiful drawings on the theme, 'My Garden'. <br/><br/>The activity not only helped the little ones to improve their eye-hand coordination but also enhanced their concentration power and creativity.",
    fullDate: "18 February, 2021",
    d: "18",
    m: "Feb",
    y: "2021",
    images: [feb_18_21_1, feb_18_21_2, feb_18_21_3, feb_18_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    title: "Yoga: The Mind-Body Connection",
    text: "Chinmaya Vidyalaya, New Delhi, conducted an Inter House Vrikshasana Challenge for the learners of class V. The competition aimed to improve the posture, balance and focus of the learners. The participants performed the asanas with perfection proving their strength and stamina.The agility and equilibrium maintained by the participants of each house during the event was praiseworthy.",
    fullDate: "08 February, 2021",
    d: "08",
    m: "Feb",
    y: "2021",
    images: [feb_08_21_1, feb_08_21_2, feb_08_21_3],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Inter House Baal Geet Competition",
    text: "Chinmaya Vidyalaya, New Delhi organised an Inter House Baal Geet Competition on 30th January, 2021 for the young singers of Classes I and II to showcase their singing talent on a virtual platform. The competition helped in promoting participation, self expression and confidence in the budding artists. The participants from the four houses put forth their enthralling performances, singing the songs with confidence and zest. All the participants left the audience fascinated with their voice modulation and rhythm, which was greatly appreciated by all.",
    fullDate: "30 January, 2021",
    d: "30",
    m: "Jan",
    y: "2021",
    images: [jan_30_21_1, jan_30_21_2, jan_30_21_3],

    isVideo: false,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },
  {
    title: "Ready for the New Normal",
    text: "As we enter 2021, adapting into the 'New Normal', we welcome our dear students and usher them in after a long gap. All preparations have been done, precautions have been taken and standards have been met as we start the partial resumption of classes XII and X. We wish our students loads of success and a happy journey into the world of learning.",
    fullDate: "27 January, 2021",
    d: "27",
    m: "Jan",
    y: "2021",
    images: [jan_27_21],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/de28vOZWSUM",
  },

  {
    fullDate: "26 January, 2021",
    title: "Republic Day Celebration - Pre Primary Department",
    text: "The Pre Primary Department of Chinmaya Vidyalaya, New Delhi celebrated Republic Day by conducting a special assembly with great patriotic spirit and fervour. The young learners were informed about many facts associated with the day.",
    d: "26",
    m: "Jan",
    y: "2021",
    images: [jan_01_21],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/jakP0_hN5lE",
  },

  {
    fullDate: "14 January, 2021",
    title: "Special Assembly on Makar Sankranti",
    text: "Chinmaya Vidyalaya, New Delhi organised a special virtual assembly on the auspicious occasion of Makar Sankranti on Thursday 14th January 2021, to welcome the New Year with chanting of the auspicious 'Gayatri Mantra' for 108 times and prayed for the well being of everyone.",
    d: "14",
    m: "Jan",
    y: "2021",
    images: [jan_14_21],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/puAEyTQX9Tc",
  },

  {
    fullDate: "13 January, 2021",
    title: "Chinmaya Vidyalaya wishes 'Happy Lohri' to everyone.",
    text: "The Pre-Primary Department of Chinmaya Vidyalaya, New Delhi celebrated the festival of 'LOHRI' virtually. The significance of this popular Punjabi harvest festival was shared with the young learners through a video. The learners were also briefed about the way this festival is celebrated with bonfire, songs, dances and savouries.",
    d: "13",
    m: "Jan",
    y: "2021",
    images: [jan_13],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/FliLTXgjAN8",
  },

  {
    fullDate: "12 January, 2021",
    title: "Youth in Action - Needs Direction",
    text: "\"You have to grow from inside out. There is no other teacher but your own soul' <br/>This inspirational message from Swami Vivekananda was the very essence of National Youth Day 12th January 2021. <br/>Students from classes I to XII of Chinmaya Vidyalaya, New Delhi celebrated this day with an array of activities to pay tribute to the great leader on his birth anniversary.<br/> An assembly was organized around the theme 'Hum se hai nayi shuruaat', which commenced with a Yoga session after which age appropriate videos of Swami Vivekananda's life were shared with the learners. It was followed by activities like creating slogans, making bookmarks, composing short poems, writing essays, group discussions etc.\"",
    d: "12",
    m: "Jan",
    y: "2021",
    images: [jan_12_21_1, jan_12_21_2, jan_12_21_3, jan_12_21_4],
    isVideo: false,
    videoSrc: "https://youtube.com/embed/puAEyTQX9Tc",
  },
  {
    title: "Geeta Jayanti and Tapovan Jayanti Celebrations",
    text: "Bhagavad Geeta is the source of eternal spiritual wisdom. To mark the birth of the holy book 'Bhagavad Geeta', Chinmaya Vidyalaya, New Delhi, celebrated Geeta Jayanti by conducting online special assembly from Classes I to XII. On this day, Tapovan Jayanti was also observed to commemorate the life and teachings of the great sage Tapovan Maharaj, the 'Guru' of Swami Chinmayanandaji. This day is celebrated as the origin of Bhagwad Geeta as on this day Lord Krishna gave discourse to Arjuna on how to face the challenges of life and perform one's duties with love and affection. The assembly aimed at highlighting the relevance of the teachings of the holy scriptures in the present scenario.",
    fullDate: "25 December 2020",
    d: "25",
    m: "Dec",
    y: "2020",
    images: [
      geetajayanti1,
      geetajayanti2,
      geetajayanti3,
      geetajayanti4,
      geetajayanti5,
    ],
    isVideo: false,
    videoSrc: "",
  },
  {
    fullDate: "4-6 December 2020",
    title: "24th Inter School Geeta Chanting Competition 2020",
    text: "The 24th Inter School Geeta Chanting Competition was held online by Chinmaya Vidyalaya, New Delhi from 4th -6th December 2020.The three-day event witnessed an overwhelming participation of 433 students from 54 schools. The students chanted the verses from Chapter 2 - Sankhya Yog of Bhagwad Geeta which reveals the immortality of soul and importance of karma. A webinar was conducted by Mr. Anil Sachdev, the Chairman, Managing Committee, Chinmaya Vidyalaya, and the Founder of SOIL, The School of Inspired Leadership on ‘Learning Agility: Finding the Roots in Bhagwad Geeta’.  He elaborated on the seven dimensions of Learning Agility that are- Curiosity, Self-Awareness, Mental Agility, People Agility, Adaptability, Resilience and Resourcefulness. It was an enriching webinar where he urged the young generation to find strength and clarity in the timeless teachings of Bhagwad Geeta.",
    d: "4-6",
    m: "Dec",
    y: "2020",
    images: [dec_06_20],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/r8FgELMxjgY",
  },
  {
    title: "Senior Felicitation Ceremony 2020",
    text: "‘Difficult roads often lead to beautiful destinations.’<br/>The senior section of Chinmaya Vidyalaya organized an Online Felicitation Ceremony - 2020 'Prashast Path’ to appreciate and applaud the efforts of the meritorious students.<br/>Dr. Dhananjay Huilgol, Post - Doctoral Fellow at Cold Spring Harbor Laboratory, New York, an Alumnus of Chinmaya Vidyalaya, New Delhi was the Chief Guest of the day.<br/>Mr. Anil Sachdev, Chairman of School Managing Committee, in his address congratulated the Principal, staff, students, and parents for their exemplary teamwork and efforts put in to rise above the crisis and urged the students to invoke the highest in themselves by practicing compassion, gratitude and collaboration to create peaceful hearts. He also highlighted six effective dimensions to become an agile learner which are:  curiosity to learn like a child, honouring one's natural talent, thinking with the whole mind, learning to deal with the people who are in pain, learning how to adapt to different situations and learning to work as a team to face challenging times.<br/>The Principal, Ms. Archana Soni, in her 'Principal's School Report' shared accolades and achievements of the Vidyalaya.<br/>The mesmerising cultural performances by students were appreciated by the parents. The event inspired the students to keep their spirits high even in these unprecedented times.",
    fullDate: "29 November 2020",
    d: "29",
    m: "Nov",
    y: "2020",
    images: [nov_29_20],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/FSLHWnI_qjk",
  },
  {
    title: "Primary Felicitation Ceremony 2020",
    text: "A man cannot discover new oceans unless he has the courage to lose sight of the shore.<br/><br/>The Primary Department of Chinmaya Vidyalaya, New Delhi, organized the Online Annual Felicitation Ceremony-2020 ' Haunsalon Ki Udaan'. Mr. Kapil Singh Director, State Nutrition Mission, Lucknow U.P, an Alumnus of Chinmaya Vidyalaya, New Delhi, graced the occasion as the Chief Guest. The Chairman of the school Management Committee Mr. Anil Sachdev, gave an inspirational address and also complimented the students and staff for their efforts. The Principal, Ms. Archana Soni, in her 'Principal's School Report' shared the accolades and achievements of the Vidyalaya. The credit winners and young achievers were honoured. The mesmerizing cultural programme was widely appreciated by the parents.",
    d: "29",
    m: "Nov",
    y: "2020",
    fullDate: "29 November 2020",
    images: [nov_29_20_2],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/MFf8EpNSjg4",
  },
  {
    fullDate: "3 October 2020",
    title: "Geometrical Yoga",
    text: "The innovative activity of blending yoga with Mathematical angles was organised for class V students of Chinmaya Vidyalaya, New Delhi. The beautiful union of mind, body and Mathematics brought together the physical and mental harmony of the students and the angles created by means of yogic asanas helped them to understand the concepts of different forms of angles .The students were also briefed about the benefits of meditation and Saatvik diet to make the session more informative.A powerpoint presentation  was shared amongst the students ,one day prior to the session to arouse  the curiosity of young learners.",
    d: "03",
    m: "Oct",
    y: "2020",
    images: [
      yoga1,
      yoga2,
      yoga3,
      yoga4,
      yoga5,
      yoga6,
      yoga7,
      yoga8,
      yoga9,
      yoga10,
      yoga11,
      yoga12,
      yoga13,
    ],
    isVideo: false,
    videoSrc: "",
  },
  {
    fullDate: "2 October 2020",
    title: "Spontaneous Overflow of Reverence",
    text: "A video presentation on the bhajan - 'Vaishnav Jan To', amalgamating orchestra, music and dance, showcased the profound emotions of the students.<br/> Chinamaya Vidyalaya, New Delhi offered heartfelt respect to the 'Father of the Nation' on the occasion of Gandhi Jayanti. Bhajans have been a medium of expressing faith since time immemorial.",
    d: "02",
    m: "Oct",
    y: "2020",
    images: [oct_02_20],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/YGRrVFiBhc0",
  },
  {
    fullDate: "14 September 2020",
    title: "Hindi Diwas",
    text: "हरि ॐ १४ सितम्बर- हिन्दी भाषा को राजभाषा के रूप में सम्मानित किया गया। प्रतिवर्ष इस विशेष दिवस को 'हिन्दी दिवस' के रूप में मनाया जाता है।हिंदी भाषा के महत्त्व, उसकी समृद्धि को उजागर करते हुए आज की इस विशेष वेला में चिन्मय  विद्‌यालय , नई दिल्ली के सौजन्य से हिंदी ई-न्यूज लेटर प्रकाशित किया जा रहा है। आशा है हमारा यह प्रयास सराहनीय सिद्ध होगा।",
    d: "14",
    m: "Sep",
    y: "2020",
    images: [sept_14_20],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/dFT22C9kjak",
    download: hindiDiwasPdf,
  },
  {
    fullDate: "15 August 2020",
    title: "Arise, Awake and Stop not till the goal is reached.",
    text: "On the occasion of 74th Independence Day, Chinmaya Vidyalaya, New Delhi, honours every patriot without whom freedom would not have been possible. The staff and students salute their priceless contribution in building independent India and making us proud citizens of this incredible nation.",
    d: "15",
    m: "Aug",
    y: "2020",
    images: [aug_15_20],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/4L_Dqf77z7g",
  },
  {
    fullDate: "15 August 2020",
    title: "Saluting the Tricolour",
    text: "Celebrating the glory of free India and upholding the pride and honour of being an Indian, the Principal of the Chinmaya Vidyalaya, New Delhi, Ms. Archana Soni, unfurled the tricolour for flag hoisting ceremony which concluded with offering salutes to the National flag.",
    d: "15",
    m: "Aug",
    y: "2020",
    images: [aug_15_20_2],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/na1xd-pWD8M",
  },
  {
    fullDate: "03 August 2020",
    title: "Pouring Devotion...  Faith...",
    text: "Chinmaya Vidyalaya, New Delhi put together its efforts to pay homage to Pujya Gurudev Swami Chinmayanandaji on Mahasamadhi Day and brought out a video expressing their undying devotion.",
    d: "03",
    m: "Aug",
    y: "2020",
    images: [aug_03_20],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/aKEtj2X5jjg",
  },
  {
    fullDate: "05 July 2020",
    title: "Guru Purnima",
    text: "Students of Chinmaya Vidyalaya New Delhi bow down in reverence to the entire Guru Parampara and Pujya Gurudev Swami Chinmayanandji on the auspicious occasion of Guru Purnima.",
    d: "05",
    m: "July",
    y: "2020",
    images: [july_05_20],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/YpI4ir6gLPo",
  },
  {
    fullDate: "21 June 2020",
    title: "Learnt about summer Solstice and the concept of solar eclipse",
    text: "On 21st June 2020 the students of Chinmaya Vidyalaya of Classes IV - X learnt about summer Solstice and the concept of solar eclipse by means of a few interesting activities - How to make a pinhole camera, toys from trash and craft activity.",
    d: "21",
    m: "June",
    y: "2020",
    images: [
      june_21_20_1,
      june_21_20_2,
      june_21_20_3,
      june_21_20_4,
      june_21_20_5,
      june_21_20_6,
      june_21_20_7,
      june_21_20_8,
      june_21_20_9,
      june_21_20_10,
      june_21_20_11,
    ],
    isVideo: false,
    videoSrc: "",
  },

  {
    fullDate: "08 May 2020",
    title: "Lockdown Learning: Message From The Principal",
    text: "A multitude of online scholastic and co-scholastic activities are being conducted under the lockdown, under the guidance of the Principal and teachers. The photos below provide a small glimpse into the activities carried out by the Pre-Primary, Secondary and Senior Secondary students. School buildings may be closed, but the school ecosystem is working harder than ever. The teaching-learning process continues, with a stronger resolve than ever before.",
    d: "08",
    m: "May",
    y: "2020",
    images: [may_08_20_message],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/zPagv4FUUXs",
  },

  {
    fullDate: "08 May 2020",
    title: "Founder's Day",
    text: "Chinmaya Vidyalaya celebrates its 104th Jayanti of our beloved Pujya Gurudev Swami Chinmayanandaji, the Founder's Day of the institutions.",
    d: "08",
    m: "May",
    y: "2020",
    images: [may_08_20],
    isVideo: true,
    videoSrc: "https://youtube.com/embed/Z2mKcqX5P0o",
  },

  {
    fullDate: "2 May 2020",
    title: "Healthsetgo - Activity Primary",
    text: "‘To touch, to move, to inspire – this is the true gift of dance.’ Students of Classes I and II put on their dancing shoes on Saturday 2nd May 2020 to participate in an energetic Bollywood Dance Fitness session with choreographer, Ms. Anushruti ",
    d: "02",
    m: "May",
    y: "2020",
    images: [
      may_02_20_1,
      may_02_20_2,
      may_02_20_3,
      may_02_20_4,
      may_02_20_5,
      may_02_20_6,
    ],
    isVideo: false,
    videoSrc: "",
  },
  {
    fullDate: "1 May 2020",
    title: "Fun Craft Session by Ms Nishi Srivastava",
    text: "“Art enables us to find ourselves and lose ourselves at the same time.” With this thought in mind, young learners of class l and ll attended an engaging and enjoyable fun craft session conducted by Ms Nishi Srivastava, Founder and CEO of The Craftsutra on 1st May 2020. ",
    d: "01",
    m: "May",
    y: "2020",
    images: [may_01_20_1, may_01_20_2, may_01_20_3, may_01_20_4, may_01_20_5],
    isVideo: false,
    videoSrc: "",
  },
  {
    fullDate: "30 April 2020",
    title: "Brushing Teeth - Primary",
    text: "For those giggles and pearly whites, students of Classes I and II participated in Talkathon Thursdays’ HealthSetGo initiative on 30th April 2020 to promote proper techniques for washing hands and of maintaining good dental hygiene. The children learnt the basics of washing hands and brushing teeth through a fun-filled live session with Dr. Sarita Agarwal, a dentist.",
    d: "30",
    m: "Apr",
    y: "2020",
    images: [
      april_30_20_1,
      april_30_20_2,
      april_30_20_3,
      april_30_20_4,
      april_30_20_5,
      april_30_20_6,
    ],
    isVideo: false,
    videoSrc: "",
  },
  {
    fullDate: "27 April 2020",
    title: "Earth Day",
    text: "Innocent concern manifested itself in form of Pre-primary students of Chinmaya Vidyalaya participating enthusiastically in various activities to commemorate our benevolent Mother Earth on EARTH DAY.",
    d: "27",
    m: "Apr",
    y: "2020",
    images: [
      april_27_20_1,
      april_27_20_2,
      april_27_20_3,
      april_27_20_4,
      april_27_20_5,
    ],
    isVideo: false,
    videoSrc: "",
  },
  {
    fullDate: "24 April 2020",
    title: "Red Day - Pre Primary",
    text: "The colour of love and smiles illuminates the air with vigour and exuberance. The Pre-Primary wing organized RED DAY on Friday 24th April 2020, for Nursery children. The shades of red made our young learners feel bright, lively and cheerful. ",
    d: "24",
    m: "Apr",
    y: "2020",
    images: [
      april_24_20_1,
      april_24_20_2,
      april_24_20_3,
      april_24_20_4,
      april_24_20_5,
      april_24_20_6,
    ],
    isVideo: false,
    videoSrc: "",
  },
  {
    title: "Chinmaya Brigade - Charged  to fight COVID19",
    text: "Students from Chinmaya Vidyalaya unleashed their creativity with the strokes of their brushes on colourful posters creating awareness about COVID 19, showing their solidarity, sense of oneness and responsibility in these trying times.",
    fullDate: "20 April 2020",
    d: "20",
    m: "Apr",
    y: "2020",
    images: [
      april_20_20_1,
      april_20_20_2,
      april_20_20_3,
      april_20_20_4,
      april_20_20_5,
      april_20_20_6,
      april_20_20_7,
      april_20_20_8,
    ],
    isVideo: false,
    videoSrc: "",
  },
  {
    fullDate: "11 April 2020",
    title: "Action4masks - Mask making activity",
    text: "Care and concern are abstract till the time they find a shape in form of action. The students of the vidyalaya participated in the unique initiative ACTION4MASKS wherein the students made hygienic, homemade masks and distributed it to the people in need.",
    d: "11",
    m: "Apr",
    y: "2020",
    images: [
      april_11_20_1,
      april_11_20_2,
      april_11_20_3,
      april_11_20_4,
      april_11_20_5,
      april_11_20_6,
    ],
    isVideo: false,
    videoSrc: "",
  },
];

export default data;
